import { type InjectionKey } from "vue";

import { type FormWrapperProvide } from "shared/components/base/FormWrapper.vue";
import { type TabsProvide } from "shared/components/base/Tabs/Tabs.vue";

export const FormWrapperSymbol = Symbol(
  "FormWrapper"
) as InjectionKey<FormWrapperProvide>;

export const TabsSymbol = Symbol("Tabs") as InjectionKey<TabsProvide>;
