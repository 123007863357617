import { $streemApiV1 } from "shared/boot/api";
import { getTimeFromSeconds } from "shared/helpers/date";
import { useUserStore } from "shared/stores/user";

export default {
  setFocusedStream(stream) {
    this.focusedStream = stream;
  },
  setFocusedBookmarkStream(stream) {
    this.focusedBookmarkStream = stream;

    if (this.focusedBookmarkStreamTimeout) {
      clearTimeout(this.focusedBookmarkStreamTimeout);
      this.focusedBookmarkStreamTimeout = null;
    }

    this.focusedBookmarkStreamTimeout = setTimeout(() => {
      this.focusedBookmarkStream = null;
    }, 1000);
  },
  setFocusedSocialStream(stream) {
    this.focusedSocialStream = stream;

    if (this.focusedSocialStreamTimeout) {
      clearTimeout(this.focusedSocialStreamTimeout);
      this.focusedSocialStreamTimeout = null;
    }

    this.focusedSocialStreamTimeout = setTimeout(() => {
      this.focusedSocialStream = null;
    }, 1000);
  },
  showMentionDetailModal(mention) {
    this.mentionDetail = mention;
  },
  getOrganisationReportSpec() {
    return $streemApiV1
      .get(
        `organisations/${
          useUserStore().currentUser.organisation.id
        }/organisation_report_spec`
      )
      .then((response) => {
        this.organisationReportSpecs = response.data;
      });
  },
  showOrganisationReportSpecs(value) {
    this.organisationReportSpecsOpen = value;
  },
  setOrganisationReportSpecList(specs) {
    const organisationReportSpecs = Object.keys(specs).map((key) => specs[key]);
    const [specification] = organisationReportSpecs;
    this.organisationReportSpecs = organisationReportSpecs;
    this.organisationReportSpec = specification;
  },
  setOrganisationReportSpec(spec) {
    if (spec) {
      Object.assign(spec, {
        scheduled_delivery_time: getTimeFromSeconds(spec.delivery_time),
      });

      this.organisationReportSpec = spec;
    }
  },
  addMentionToOrganisationReport(value) {
    this.addedOrganisationReportMention = value;
  },
};
