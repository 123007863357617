<template>
  <a
    href="#"
    :class="$isDesktop ? 'q-mx-md q-pr-md' : 'q-mx-sm q-pr-sm'"
    class="dropdown flex items-center"
    @click.prevent
  >
    <div class="flex items-center">
      <QIcon
        :name="icons[selectedOption]"
        :size="$isDesktop ? '24px' : '19px'"
        :class="$isMobile && 'q-pr-xs'"
      />
      <span
        v-if="$isDesktop"
        class="q-ml-sm"
      >
        {{ labels[selectedOption] }}
      </span>
    </div>

    <QIcon
      name="ion-md-arrow-dropdown"
      :size="$isDesktop ? '23px' : '16px'"
      :class="{
        'q-ml-md': $isDesktop,
      }"
    />

    <QMenu
      v-model="open"
      class="bg-white"
    >
      <QList>
        <QItem
          v-for="(label, key) in labels"
          :key="key"
          :active="selectedOption === key"
          class="flex items-center"
          clickable
          @click="selectOption(key)"
        >
          <QIcon
            :name="icons[key]"
            size="19px"
          />
          <span class="text-paragraph q-ml-sm">
            {{ label }}
          </span>
        </QItem>
      </QList>
    </QMenu>
  </a>
</template>

<script>
import { storeToRefs } from "pinia";

import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

export default {
  name: "UniversalSearchDropdown",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const userStore = useUserStore();
    const { adminUserEnabled, customerViewEnabled } = storeToRefs(userStore);

    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return { adminUserEnabled, customerViewEnabled, appConfig };
  },
  data() {
    return {
      open: false,
      icons: {
        all: "ion-globe",
        streams: "ion-search",
        journalists: "ion-person",
      },
    };
  },
  computed: {
    labels() {
      const labels = {
        all: this.$t("universal_search_dropdown.all_label"),
        streams: this.$t("universal_search_dropdown.streams_label"),
        journalists: this.$t("universal_search_dropdown.journalists_label"),
      };

      // admin users and only those with the feature and permission enabled
      if (!this.canSearchAvailableContent) delete labels.all;

      // journalists not available for UK currently
      if (this.appConfig.platformName === "CisionOne")
        delete labels.journalists;

      // journalists not available when Outreach 2 enabled
      if (this.$features.has("has_outreach_2")) delete labels.journalists;

      return labels;
    },
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    canSearchAvailableContent() {
      if (this.adminUserEnabled && !this.customerViewEnabled) return true;

      return (
        this.$features.has("has_search_available_content") &&
        this.$permissions.has("can_search_available_content")
      );
    },
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.toggleDropdown();

      this.$track("Changed Universal Search Scope", {
        scope: option,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  border-right: 1px solid #ddd;

  :deep(.q-menu :deep(.q-item.active)) {
    background: #ddd;
  }
}
</style>
