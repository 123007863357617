<template>
  <div
    v-if="hasAmplify"
    class="row items-baseline"
  >
    <SocialAmplifyIcon
      :mention="mention"
      :total="totalSocialAmplifyVolume"
      @click="onSocialAmplifyClicked"
    />

    <SocialAmplifyPopup
      v-if="totalSocialAmplifyVolume"
      v-model="showSocialAmplifyPopup"
      :mention="mention"
      :data="socialAmplifyData"
      :total="totalSocialAmplifyVolume"
      :root-element="rootElement"
    />
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import {
  SocialAmplifyIcon,
  SocialAmplifyPopup,
  useSocialAmplify,
} from "shared/components/SocialAmplify";
import { useUserStore } from "shared/stores/user";

export default {
  name: "OnlineMentionCardMetrics",
  components: {
    SocialAmplifyIcon,
    SocialAmplifyPopup,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    isListView: {
      type: Boolean,
    },
    isParentVisible: {
      type: Boolean,
    },
    rootElement: {
      type: HTMLElement,
      required: true,
    },
  },
  emits: ["click"],
  setup(props) {
    const {
      socialAmplifyData,
      showSocialAmplifyPopup,
      hasAmplify,
      totalSocialAmplifyVolume,
      loadSocialAmplifyData,
      toggleSocialAmplify,
    } = useSocialAmplify(props);

    const userStore = useUserStore();
    const { currentUser, isAdminUser } = storeToRefs(userStore);

    return {
      socialAmplifyData,
      showSocialAmplifyPopup,
      hasAmplify,
      totalSocialAmplifyVolume,
      loadSocialAmplifyData,
      toggleSocialAmplify,
      currentUser,
      isAdminUser,
    };
  },
  watch: {
    isParentVisible: {
      handler(value) {
        if (value === false) {
          this.showSocialAmplifyPopup = false;
        }
      },
    },
  },
  mounted() {
    if (this.hasAmplify) {
      this.loadSocialAmplifyData();
    }
  },
  methods: {
    onSocialAmplifyClicked() {
      if (!this.showSocialAmplifyPopup) {
        this.rootElement.scrollIntoView({ behaviour: "smooth" });
        this.$track("Opened Social Amplify");
      }

      this.toggleSocialAmplify();
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 140px;
  height: 35px;
  margin-top: 4px;
  margin-right: 8px;
}
</style>
