<template>
  <Transition
    ref="container"
    name="fade"
    @after-leave="destroyPopper"
  >
    <div
      v-if="visible"
      ref="element"
      v-click-outside="clickOutsideConfig"
      class="popper-element"
      :class="{ caret: caret }"
      v-bind="listeners"
    >
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import {
  createPopper as createPopperInstance,
  type Instance as PopperInstance,
  Modifier,
  type Placement,
} from "@popperjs/core";
import { type FlipModifier } from "@popperjs/core/lib/modifiers/flip";
import {
  OffsetModifier,
  type Options as OffsetOptions,
} from "@popperjs/core/lib/modifiers/offset";
import { type Options as PreventOverflowOptions } from "@popperjs/core/lib/modifiers/preventOverflow";
import vClickOutsidePlugin, {
  type ClickOutsideVue3DirectiveOptions,
} from "click-outside-vue3";
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useAttrs,
  watch,
} from "vue";

export interface PopperProps {
  boundary?: HTMLElement | null;
  caret?: boolean;
  clickOutsideCapture?: boolean;
  clickOutsideMiddleware?: (event: MouseEvent) => boolean;
  disableClickOutside?: boolean;
  fallbackPlacements?: Placement[];
  flip?: boolean;
  offset?: OffsetOptions["offset"];
  placement?: Placement;
  rootElement?: HTMLElement | null;
  sameWidth?: boolean;
  tether?: boolean;
  tetherOffset?: number;
  width?: number | null;
}

const visible = defineModel({
  type: Boolean,
});

const props = withDefaults(defineProps<PopperProps>(), {
  boundary: null,
  fallbackPlacements: () => ["top"],
  clickOutsideMiddleware: () => true,
  offset: undefined,
  placement: "bottom",
  rootElement: null,
  tetherOffset: 0,
  width: null,
});

const { directive: vClickOutside } = vClickOutsidePlugin;

const attrs = useAttrs();
const root = ref<HTMLElement | null>(null);
const element = ref<HTMLDivElement | null>(null);
const container = ref<HTMLDivElement | null>(null);
const popperInstance = ref<PopperInstance>();

const offsetModifier = computed<Pick<OffsetModifier, "name" | "options">>(
  () => ({
    name: "offset",
    options: {
      offset: props.offset,
    },
  })
);

const flipOptions = computed<Pick<FlipModifier, "name" | "options">>(() => ({
  name: "flip",
  ...(props.fallbackPlacements.length
    ? {
        options: { fallbackPlacements: props.fallbackPlacements },
      }
    : {}),
}));

const tetherOptions = computed<
  Pick<PreventOverflowOptions, "tether" | "tetherOffset">
>(() => ({
  tether: props.tether,
  tetherOffset: () => props.tetherOffset,
}));

const sameWidthModifier = computed<Modifier<"sameWidth", {}>>(() => ({
  name: "sameWidth",
  enabled: true,
  phase: "write",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      (state.elements.reference as HTMLElement).offsetWidth
    }px`;
  },
}));

const widthModifier = computed<Modifier<"width", {}>>(() => ({
  name: "width",
  enabled: true,
  phase: "write",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${props.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${props.width}px`;
  },
}));

const listeners = computed<Record<string, any>>(() =>
  // need to remove input listener that gets passed down when Popper is used with v-model
  Object.entries(attrs).reduce((acc: Record<string, any>, [key, listener]) => {
    if (key !== "update:modelValue") acc[key] = listener;

    return acc;
  }, {})
);

function beforeHandler(event: MouseEvent): boolean {
  const middlewareResult = props.clickOutsideMiddleware?.(event) ?? true;

  // prevents edge cases on mousemoves within the element
  const isNotDocumentElement = event.target !== document.documentElement;

  return middlewareResult && isNotDocumentElement;
}

function toggleVisibility(): void {
  visible.value = !visible.value;
}

const clickOutsideConfig = computed<ClickOutsideVue3DirectiveOptions>(() => ({
  handler: toggleVisibility,
  middleware: beforeHandler,
  events: ["click"],
  isActive: !props.disableClickOutside && visible.value,
  capture: props.clickOutsideCapture,
}));

function findParentEl(): HTMLElement | null {
  const excludedClasses = ["popper-element", "vue-portal-target"];

  let parent = container.value?.parentElement || null;

  while (
    parent?.parentElement &&
    Array.from(parent.classList || []).some((klass) =>
      excludedClasses.includes(klass)
    )
  ) {
    parent = parent?.parentElement;
  }

  return parent;
}

function destroyPopper() {
  if (popperInstance.value) {
    popperInstance.value.destroy();
    popperInstance.value = undefined;
  }
}

async function createPopper(): Promise<void> {
  if (visible.value) destroyPopper();

  await nextTick();

  const target = props.rootElement || root.value;

  if (!target || !element.value) return;

  popperInstance.value = createPopperInstance(target, element.value, {
    placement: props.placement,
    strategy: "fixed",
    modifiers: [
      ...(props.flip ? [flipOptions.value] : []),
      ...(props.offset?.length ? [offsetModifier.value] : []),
      ...(props.sameWidth ? [sameWidthModifier.value] : []),
      ...(!props.sameWidth && props.width ? [widthModifier.value] : []),
      {
        name: "preventOverflow",
        options: {
          boundary: props.boundary || "viewport",
          rootBoundary: "document",
          ...tetherOptions.value,
        },
      },
    ],
  });
}

function showPopper(): void {
  if (visible.value) {
    createPopper();
  }
}

watch(visible, showPopper);

watch(() => props.rootElement, showPopper);

onMounted(() => {
  root.value = findParentEl();

  showPopper();
});

onUnmounted(() => {
  destroyPopper();
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.popper-element {
  z-index: 7500;
  display: inline-block;

  *.bordered {
    border: 1px solid $ds-denim-4;
  }

  &.caret {
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 10px;
      width: 10px;
      background: linear-gradient(-225deg, transparent, white 50%);
      border: inherit;
      border-width: 0 1px 1px 0;
      border-radius: 0 0 3px;
    }

    &[data-popper-placement="top"]::after {
      bottom: -1px;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }

    &[data-popper-placement="bottom"]::after {
      left: 50%;
      top: -1px;
      transform: translate(-50%, -50%) rotate(-135deg);
    }
  }
}
</style>
