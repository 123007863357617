<template>
  <BaseButtonDropdown
    v-model="pickerOpen"
    new-design
    new-dropdown
    :placement="placement"
    :fallback-placements="fallbackPlacements"
    flip
    class="c-date-picker"
  >
    <template #btn="{ toggle }">
      <slot
        name="btn"
        :label="buttonLabel"
        :after-label="afterLabel"
        :before-label="beforeLabel"
        :toggle="toggle"
        :open="pickerOpen"
      >
        <BaseButtonGroup>
          <BaseButton
            class="c-date-picker__button"
            new-design
            new-icon
            icon="schedule-post"
            :label="buttonLabel"
            v-bind="inheritedAttributes"
            @click="toggle"
          />
          <BaseButton
            v-if="clearable && hasValue"
            new-design
            new-icon
            icon="close"
            v-bind="inheritedAttributes"
            @click="clear"
          />
        </BaseButtonGroup>
      </slot>
    </template>
    <InputDate
      :model-value="modelValue"
      :range="range"
      :range-selection="rangeSelection"
      :use-local-time-zone="useLocalTimeZone"
      v-bind="inheritedAttributes"
      @update:model-value="updateDate"
      @update:range="updateRange"
      @cancel="cancelRange"
      @updated="updated"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </InputDate>
  </BaseButtonDropdown>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { omit } from "lodash-es";
import { storeToRefs } from "pinia";
import { computed, ref, useAttrs, watch } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import type { PopperProps } from "shared/components/base/Popper.vue";
import type {
  CalendarModel,
  CalendarProps,
} from "shared/composables/useCalendar";
import {
  formatDate,
  formatIntlDate,
  fromCurrentToGivenTimezone,
  getTimezone,
} from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { useUserStore } from "shared/stores/user";
import type { Nullable } from "shared/types";

import BaseButton from "./BaseButton.vue";
import BaseButtonDropdown from "./BaseButtonDropdown.vue";
import BaseButtonGroup from "./BaseButtonGroup.vue";
import InputDate from "./InputDate.vue";

export interface InputDatePickerProps {
  dateFormat?: Nullable<string>;
  rangeFormat?: Nullable<string>;
  rangeSelection?: CalendarProps["rangeSelection"];
  dateStyle?: Nullable<Intl.DateTimeFormatOptions["dateStyle"]>;
  timeStyle?: Nullable<Intl.DateTimeFormatOptions["timeStyle"]>;
  formatOptions?: Nullable<Intl.DateTimeFormatOptions>;
  range?: Nullable<CalendarProps["range"]>;
  label?: string;
  closeOnUpdate?: boolean;
  placeholder?: string;
  clearable?: boolean;
  placement?: PopperProps["placement"];
  fallbackPlacements?: PopperProps["fallbackPlacements"];
  useLocalTimeZone?: boolean;
}

const model = defineModel<CalendarModel>({
  default: null,
});

const props = withDefaults(defineProps<InputDatePickerProps>(), {
  dateFormat: null,
  rangeFormat: null,
  rangeSelection: "day",
  dateStyle: null,
  timeStyle: null,
  formatOptions: null,
  range: null,
  label: "",
  placeholder: "",
  placement: "bottom-start",
  fallbackPlacements: () => ["top-start", "top-end"],
});

const emit = defineEmits<{
  "update:range": [DateRange | null];
  open: [];
  cancel: [];
  updated: [];
}>();

const attrs = useAttrs();

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

const pickerOpen = ref(false);

const localDateTimeStyle = computed<Intl.DateTimeFormatOptions | null>(() => {
  if (!props.dateStyle && !props.timeStyle) {
    return null;
  }

  const style: Intl.DateTimeFormatOptions = {};

  if (props.dateStyle) {
    style.dateStyle = props.dateStyle;
  }

  if (props.timeStyle) {
    style.timeStyle = props.timeStyle;
  }

  return style;
});

const localFormatOptions = computed<Intl.DateTimeFormatOptions>(() => {
  if (props.formatOptions) {
    return props.formatOptions;
  }

  if (localDateTimeStyle.value) {
    return localDateTimeStyle.value;
  }

  if (props.rangeSelection !== "day") {
    return {
      month: "short",
      day: "numeric",
    };
  }

  return {
    dateStyle: "short",
  };
});

const localTimeZone = computed<string>(() =>
  props.useLocalTimeZone ? getTimezone() : currentUser.value.time_zone
);

const hasSameTimezone = computed<boolean>(
  () => getTimezone() === currentUser.value.time_zone
);

const localValue = computed<Date | null>(() => {
  if (model.value) {
    return fromCurrentToGivenTimezone(model.value, localTimeZone.value);
  }

  return null;
});

const localRange = computed<DateRange | null>(() => {
  if (props.range) {
    return DateRange.fromDates(
      fromCurrentToGivenTimezone(props.range.after * 1000, localTimeZone.value),
      fromCurrentToGivenTimezone(props.range.before * 1000, localTimeZone.value)
    );
  }

  return null;
});

const inheritedAttributes = computed<Omit<typeof attrs, "class" | "style">>(
  () => omit(attrs, ["class", "style"])
);

const buttonLabel = computed<string>(() => {
  if (props.label) {
    return props.label;
  }

  if (localValue.value) {
    if (props.dateFormat) {
      return format(localValue.value, props.dateFormat);
    }

    return formatIntlDate(localValue.value, localFormatOptions.value);
  }

  if (localRange.value) {
    if (hasSameTimezone.value && props.range) {
      if (props.rangeFormat) {
        return props.range.toString(props.rangeFormat);
      }

      return props.range.toIntlString(localFormatOptions.value);
    }

    const after = new Date(localRange.value.after * 1000);
    const before = new Date(localRange.value.before * 1000);

    if (props.rangeFormat) {
      return getLocaleText("date.pretty_ranges.range", {
        after: format(after, props.rangeFormat),
        before: format(before, props.rangeFormat),
      });
    }

    return getLocaleText("date.pretty_ranges.range", {
      after: formatIntlDate(after, localFormatOptions.value),
      before: formatIntlDate(before, localFormatOptions.value),
    });
  }

  if (props.placeholder) {
    return props.placeholder;
  }

  return "";
});

const afterLabel = computed<string>(() => {
  if (localRange.value) {
    if (props.dateFormat) {
      return formatDate(localRange.value.after, props.dateFormat);
    }

    return formatIntlDate(localRange.value.after, localFormatOptions.value);
  }

  return "";
});

const beforeLabel = computed<string>(() => {
  if (localRange.value) {
    if (props.dateFormat) {
      return formatDate(localRange.value.before, props.dateFormat);
    }

    return formatIntlDate(localRange.value.before, localFormatOptions.value);
  }

  return "";
});

const hasValue = computed<boolean>(() => Boolean(model.value || props.range));

watch(pickerOpen, (value) => {
  if (value) {
    emit("open");
  }
});

function updateDate(date: CalendarModel): void {
  model.value = date;

  if (props.closeOnUpdate) {
    pickerOpen.value = false;
  }
}

function updateRange(range: DateRange | null) {
  emit("update:range", range);

  if (props.closeOnUpdate) {
    pickerOpen.value = false;
  }
}

function cancelRange(): void {
  pickerOpen.value = false;
  emit("cancel");
}

function updated() {
  pickerOpen.value = false;
  emit("updated");
}

function clear() {
  model.value = null;
  emit("update:range", null);
}
</script>

<style lang="scss" scoped>
.c-date-picker {
  display: flex;
  flex-direction: column;

  &__button {
    flex: 1;

    :deep() {
      span:last-child {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>
