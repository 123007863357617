<template>
  <div
    class="card row justify-around soft text-center q-py-md no-shrink"
    style="margin-top: 0"
  >
    <a
      v-for="(choice, index) in choices"
      :key="index"
      :class="anchorClass(choice)"
      href="#"
      class="col-4 q-pa-sm"
      @click.prevent="setRange(choice)"
    >
      {{ choice.toString() }}
    </a>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import DateRange from "shared/helpers/DateRange";
import { useUserStore } from "shared/stores/user";

export default {
  name: "RangeSelector",
  props: {
    range: {
      type: DateRange,
      required: true,
      default: () => DateRange.today(),
    },
  },
  emits: ["update:range"],
  setup() {
    const userStore = useUserStore();
    const { organisation } = storeToRefs(userStore);

    return { organisation };
  },
  data() {
    return {
      choices: [
        DateRange.today(),
        DateRange.yesterday(),
        DateRange.lastThreeDays(),
        DateRange.lastSevenDays(),
        DateRange.lastThirtyDays(),
        DateRange.lastNinetyDays(),
        DateRange.currentMonth(),
        DateRange.lastMonth(),
      ],
    };
  },
  computed: {
    canShowYear() {
      return this.organisation.max_date_range === 12;
    },
  },
  mounted() {
    if (this.canShowYear) {
      this.choices.push(DateRange.lastYear());
    }
  },
  methods: {
    anchorClass(choice) {
      return {
        highlight: this.range && this.range.toString() === choice.toString(),
      };
    },
    setRange(range) {
      this.$emit("update:range", range);
    },
  },
};
</script>
