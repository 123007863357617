import { computed, onMounted, ref, toRefs } from "vue";

import { AlertDialog } from "shared/boot/alert";
import { getLocaleText } from "shared/boot/i18n";
import { rollbarError } from "shared/boot/rollbar";
import { sentimentOptions } from "shared/components/core/pickers/SentimentPicker";
import type { SentimentOption } from "shared/components/core/pickers/SentimentPicker/sentimentOptions";
import { timeAgo } from "shared/helpers/date";
import { constantize } from "shared/helpers/string";
import SentimentRatingService from "shared/services/api/SentimentRatingService";
import type { Origin, SentimentRating, Stream, Target } from "shared/types";
import type {
  CustomerArticle,
  Mention as MentionType,
  Sentiment,
} from "shared/types/mentions";

interface MentionSentimentOption
  extends Omit<SentimentOption, "range" | "sentiment" | "value"> {
  value: number | null;
  inRange: (sentiment?: Sentiment) => boolean;
}

export type Mention = Exclude<MentionType, CustomerArticle>;

export interface MentionSentimentProps {
  mention: Mention;
  stream: Stream;
}

interface MentionSentimentRating
  extends Partial<Omit<SentimentRating, "sentiment">> {
  sentiment: Sentiment;
}

export default function useMentionSentiment(props: MentionSentimentProps) {
  const { mention, stream } = toRefs(props);

  const allSentimentOptions: MentionSentimentOption[] = [
    {
      value: null,
      label: "No Rating",
      field: "none",
      color: "#bfbfbf",
      icon: "fas fa-meh-blank",
      inRange: () => false,
    },
    ...(sentimentOptions as MentionSentimentOption[]),
  ];

  const allSentimentRatingOptions = computed<MentionSentimentOption[]>(() =>
    allSentimentOptions.map((sentimentOption) => ({
      ...sentimentOption,
      label: getLocaleText(`sentiment.${sentimentOption.field}`),
      style: {
        "--icon-color": sentimentOption.color,
      },
    }))
  );

  const sentimentRatingOptions = computed<MentionSentimentOption[]>(() =>
    allSentimentRatingOptions.value.filter(
      (sentimentOption) => sentimentOption.value !== null
    )
  );

  const sentimentRating = ref<MentionSentimentRating>({
    sentiment: mention.value.sentiment,
  });

  const sentimentOption = computed<MentionSentimentOption | undefined>(() =>
    allSentimentRatingOptions.value.find(
      (sentimentRatingOption) =>
        (typeof sentimentRating.value.sentiment !== "number" &&
          sentimentRatingOption.value === null) ||
        sentimentRatingOption.inRange(sentimentRating.value.sentiment)
    )
  );

  const mentionSentimentRating = ref<MentionSentimentRating>({
    sentiment: mention.value.sentiment,
  });

  const mentionSentimentOption = computed<MentionSentimentOption | undefined>(
    () =>
      allSentimentRatingOptions.value.find(
        (sentimentRatingOption) =>
          typeof mentionSentimentRating.value.sentiment === "number" &&
          sentimentRatingOption.inRange(mentionSentimentRating.value.sentiment)
      )
  );

  const origin = computed<Origin>(() => ({
    origin_id: stream.value.id as number,
    origin_type: "Stream",
  }));

  const target = computed<Target>(() => ({
    target_id: mention.value.id,
    target_type: constantize(mention.value.type),
  }));

  const hasUserSentimentRating = computed(() =>
    Boolean(sentimentRating.value.id)
  );

  const sentimentRatingTooltip = computed(() => {
    if (hasUserSentimentRating.value) {
      return getLocaleText("sentiment_picker.last_rated_by", {
        userName: sentimentRating.value.user?.name,
        latestRating: timeAgo(sentimentRating.value.updated_at as string),
      });
    }

    if (typeof mentionSentimentRating.value.sentiment === "number") {
      return getLocaleText("sentiment_picker.item_automatically_rated_as", {
        label: sentimentOption.value?.label,
      });
    }

    return "";
  });

  const allowSentimentRatingForStream = computed(
    () => stream.value.can_modify || stream.value.locked
  );

  const allowSentimentRating = computed(() =>
    Boolean(allowSentimentRatingForStream.value)
  );

  function updateSentimentRating() {
    if (mention.value.sentiment) {
      mentionSentimentRating.value.sentiment =
        Math.round(Number(mention.value.sentiment) * 100) / 100;

      sentimentRating.value.sentiment =
        Math.round(Number(mention.value.sentiment) * 100) / 100;
    }

    if (!allowSentimentRating.value) {
      return;
    }

    const manualRating =
      mention.value.sentiment_ratings?.find(
        (rating: SentimentRating) =>
          rating.origin_id === stream.value.id &&
          rating.origin_type === "Stream"
      ) || mention.value.sentiment_ratings?.at(-1);

    if (
      manualRating &&
      (manualRating.origin_id === stream.value.id || !manualRating.origin_id)
    ) {
      sentimentRating.value = manualRating;
    }
  }

  async function saveSentimentRating(value: number) {
    if (!allowSentimentRating.value) {
      return;
    }

    const existingRating = { ...sentimentRating.value };

    try {
      sentimentRating.value.sentiment = value;

      const newSentimentRating: SentimentRating = {
        origin_id: origin.value.origin_id,
        origin_type: origin.value.origin_type,
        target_id: target.value.target_id,
        target_type: target.value.target_type,
        sentiment: sentimentRating.value.sentiment,
      };

      sentimentRating.value = (
        await SentimentRatingService.save(newSentimentRating)
      ).data;

      if (!mention.value.sentiment_ratings) {
        Object.assign(mention.value, { sentiment_ratings: [] });
      } else {
        const existingRatingIndex = mention.value.sentiment_ratings.findIndex(
          (rating) => rating.id === sentimentRating.value.id
        );

        if (
          typeof existingRatingIndex === "number" &&
          existingRatingIndex >= 0
        ) {
          mention.value.sentiment_ratings.splice(
            existingRatingIndex,
            1,
            sentimentRating.value as SentimentRating
          );
        } else {
          mention.value.sentiment_ratings.push(
            sentimentRating.value as SentimentRating
          );
        }
      }
    } catch (error) {
      sentimentRating.value = existingRating;
      AlertDialog(getLocaleText("mention_card.sentiment_rating_error"));
      rollbarError(error);
    }
  }

  onMounted(updateSentimentRating);

  return {
    sentimentRatingOptions,
    sentimentRating,
    sentimentOption,
    mentionSentimentRating,
    mentionSentimentOption,
    hasUserSentimentRating,
    sentimentRatingTooltip,
    allowSentimentRating,
    saveSentimentRating,
  };
}
