<template>
  <QSelect
    v-model="tierFilters"
    :options="tierOptions"
    :for="`tier-select-${uid}`"
    use-chips
    dense
    multiple
    borderless
    stack-label
    clearable
    class="full-width"
    :label="$t('tier_filters.select_tiers')"
  >
    <template #selected-item="scope">
      <QChip
        dense
        square
        removable
        text-color="primary"
        class="q-mt-sm text-white bg-online"
        @remove="scope.removeAtIndex(scope.index)"
      >
        {{ scope.opt.label }}
      </QChip>
    </template>
  </QSelect>
</template>

<script>
import generateId from "shared/helpers/generateId";

export default {
  name: "TierFilters",
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    stream: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      uid: generateId(),
    };
  },
  computed: {
    tierFilters: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    tierOptions() {
      const options = [];

      for (let tier = 1; tier <= 5; tier += 1) {
        options.push({
          label: `Tier ${tier}`,
          value: tier,
        });
      }

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-field__native) {
  width: 100% !important;
}

.medium-group {
  font-size: 1em;
  font-weight: bold;
  display: block;
  background-color: #f5f5f5;
  padding: 5px 5px 5px 10px;
}

.item-label {
  padding-left: 5px;
}
</style>
