<template>
  <MentionCard
    ref="card"
    v-intersection="intersectionConfig"
    :title="mentionTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :options="options"
    :no-highlighting="noHighlighting"
    :show-checkbox="showCheckbox"
    :always-show-keywords="alwaysShowKeywords"
    :selected="selected"
    :class="mentionCardClass"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #before>
      <div
        v-if="mention.on_front_page && mention.og_image"
        :style="{ backgroundImage: 'url(\'' + mention.og_image + '\')' }"
        class="article-thumbnail"
      />
    </template>

    <template #innerTitle="{ title }">
      <span class="title-hover">
        {{ title }}
      </span>
    </template>

    <template #header>
      <span
        v-if="mention.syndications && mention.syndications.length > 0"
        class="alternate q-mt-xs source-title"
        @click.stop="displaySyndication = !displaySyndication"
      >
        <EllipseContent>
          {{ mention.source.name }}
        </EllipseContent>
        + {{ mention.syndications.length }}
      </span>
      <span
        v-else
        class="alternate q-mt-xs source-title"
      >
        <EllipseContent>
          {{ mention.source.name }}
        </EllipseContent>
      </span>
      <span class="softer">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span>
          {{
            $t("online_mention_card.word_count", {
              wordCount: mention.word_count,
            })
          }}
        </span>
      </span>
    </template>

    <slot />

    <template #beforeBody>
      <Syndications
        :expanded="displaySyndication"
        :mention="mention"
        :stream="stream"
        @syndication-clicked="mentionClicked($event)"
      />
    </template>

    <template #list-body-append>
      <OnlineMentionCardMetrics
        v-if="card"
        is-list-view
        :mention="mention"
        :is-parent-visible="isVisible"
        :root-element="card.$el"
        @click="onClick"
      />
    </template>

    <template #afterBody>
      <div class="soft q-mt-md after-body items-center">
        <OnlineMentionCardMetrics
          v-if="card"
          :mention="mention"
          :is-parent-visible="isVisible"
          :root-element="card.$el"
          @click="onClick"
        />
        <PotentialAudienceReachWidget :mention="mention" />
        <AdvertisingValueWidget
          class="soft"
          :mention="mention"
        />
        <SentimentWidget
          :mention="mention"
          :stream="stream"
        />
        <ReactScoreWidget
          class="soft"
          :mention="mention"
          :is-parent-visible="isVisible"
        />
      </div>

      <MentionTranslationLanguage
        v-if="showTranslationLanguage"
        :mention="mention"
      />
    </template>

    <template #after>
      <!-- Related Print Article -->
      <div v-if="canShowRelatedArticle">
        <RelatedMentionCard
          :action-sheet-component="actionSheetComponent"
          :mention="mention.related_article"
          @mention:click="mentionClicked($event)"
          @syndication:click="mentionClicked($event)"
        >
          {{ $t("online_mention_card.view_print_version") }}
        </RelatedMentionCard>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";
import { ref } from "vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { EllipseContent } from "shared/components/base";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionTranslationLanguage from "shared/components/MentionTranslationLanguage.vue";
import OnlineMentionCardMetrics from "shared/components/OnlineMentionCardMetrics.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import { RelatedMentionCard } from "shared/components/RelatedMentionCard";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import Syndications from "shared/components/Syndications.vue";
import useMention from "shared/composables/useMention";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

export default {
  name: "OnlineMentionCard",
  components: {
    PotentialAudienceReachWidget,
    EllipseContent,
    MentionCard,
    MentionTranslationLanguage,
    OnlineMentionCardMetrics,
    RelatedMentionCard,
    AuthorWidget,
    Syndications,
    AdvertisingValueWidget,
    SentimentWidget,
    ReactScoreWidget,
  },
  props,
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const card = ref(null);

    const { isMentionNotClickable, mentionClicked } = useMention(
      mentionProps,
      context
    );

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen } = storeToRefs(universalSearchStore);

    const userStore = useUserStore();

    const { adminUserEnabled, betaEnabled, currentUser, isAdminUser } =
      storeToRefs(userStore);

    return {
      card,
      isMentionNotClickable,
      mentionClicked,
      searchOpen,
      adminUserEnabled,
      betaEnabled,
      currentUser,
      isAdminUser,
    };
  },
  data() {
    return {
      displaySyndication: false,
      isVisible: false,
      hasRecorded: false,
      intersectionConfig: {
        handler: this.onIntersection,
        cfg: {
          threshold: [0, 0.25, 0.5, 0.75, 1],
        },
      },
    };
  },
  computed: {
    canShowRelatedArticle() {
      return (
        this.$features.has("view_paper_articles") &&
        this.mention.related_article &&
        (!this.searchOpen || this.adminUserEnabled)
      );
    },
    mentionTitle() {
      return this.mention.translated_title || this.mention.title;
    },
    showTranslationLanguage() {
      return this.mention.original_language !== "English";
    },
    mentionCardClass() {
      if (this.isMentionNotClickable(this.mention)) return null;

      return "pointer";
    },
  },
  methods: {
    onIntersection({ isIntersecting, intersectionRatio }) {
      this.isVisible = isIntersecting;

      this.recordView();

      if (intersectionRatio < 0.75) {
        this.isVisible = false;
      }
    },
    onClick() {
      this.$refs.card.$el.scrollIntoView({ behaviour: "smooth" });
    },
    recordView() {
      if (this.isVisible && this.$route.name !== "curated-email-report") {
        setTimeout(() => {
          if (this.isVisible && !this.isAdminUser && !this.hasRecorded) {
            this.hasRecorded = true;

            this.$track("Saw Online Article", {
              userId: this.currentUser.id,
              articleId: this.mention.id,
              sourceId: this.mention.source.id,
            });
          }
        }, 300);
      }
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-thumbnail {
  width: 100%;
  background-size: cover;
  background-position: 50% 30%;
  height: 120px;
}

.helper-text {
  margin-top: 9px;
}

.source-title :deep(.ellipsis) {
  display: inline-block;
  max-width: 210px;
  white-space: normal;
  vertical-align: bottom;
}

.after-body {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
