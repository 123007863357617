import { useUserStore } from "shared/stores/user";

// eslint-disable-next-line import/prefer-default-export
export function useUserLocation(organisation = null) {
  function getCurrentUserLocation() {
    const userStore = useUserStore();
    const region = userStore.currentUser.organisation.region || "au";
    const timezone = userStore.currentUser.time_zone;

    return {
      region,
      timezone,
    };
  }

  function getUserLocation() {
    const location = organisation
      ? { region: organisation.region, timezone: organisation.time_zone }
      : getCurrentUserLocation();

    const { timezone } = location;
    let { region } = location;

    const [continent] = timezone?.split("/") || [];

    if (region === "uk") {
      region = "gb";
    }

    return {
      continent,
      region: region.toUpperCase(),
    };
  }

  return {
    getUserLocation,
  };
}
