<template>
  <div
    data-cy="base-expansion-panel"
    class="tw-flex tw-flex-col"
    :class="{
      'tw-bg-white': !menu,
      'tw-border tw-border-solid tw-border-denim-300': bordered,
      'tw-rounded': bordered && dense,
      'tw-rounded-md': bordered && !dense,
      'tw-flex-1': fitContainer && expanded,
      'tw-overflow-hidden': !expanded || fitContainer,
    }"
  >
    <div
      data-cy="base-expansion-panel-header"
      class="tw-flex tw-items-center"
      :class="{
        'tw-rounded-t': bordered && dense,
        'tw-rounded-t-md': bordered && !dense,
        'tw-bg-white hover:tw-bg-denim-100': !menu,
        'tw-flex-row-reverse hover:tw-bg-white/15': menu,
        'tw-bg-white/20': menu && active,
        'tw-sticky tw-top-0 tw-z-50': stickyHeader && expanded,
        'tw-cursor-pointer': !disabled,
      }"
      :style="headerStyle"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
    >
      <slot
        name="header"
        :toggle="togglePanel"
        :open="openPanel"
        :close="closePanel"
        :disabled="disabled"
        :mouseover="mouseover"
      >
        <div
          class="tw-flex tw-flex-1 tw-flex-col tw-gap-1"
          :class="{
            'tw-px-1.5 tw-py-1': !noPadding && (dense || menu),
            'tw-p-4': !noPadding && !dense && !menu,
          }"
          @click="togglePanel"
        >
          <div :class="{ 'tw-font-bold': boldLabel }">{{ label }}</div>
          <div
            v-if="caption"
            class="tw-text-xs tw-font-bold tw-text-danger"
          >
            {{ caption }}
          </div>
        </div>
      </slot>
      <div
        v-if="!disabled"
        :class="{
          'tw-opacity-0': hoverToggle && !mouseover,
          'hover:tw-bg-white/15': menu,
          'tw-px-0.5 tw-py-2': !noPadding && menu,
          'tw-px-1.5 tw-py-1': !noPadding && dense && !menu,
          'tw-p-4': !noPadding && !dense && !menu,
        }"
        @click="togglePanel"
      >
        <div
          class="tw-transition-all tw-duration-300"
          :class="{
            'tw-rotate-180': expanded && !menu,
            'tw-rotate-90': expanded && menu,
          }"
        >
          <BaseIcon
            :icon="menu ? 'angle-right' : 'angle-down'"
            :class="{ 'tw-text-xl': !dense && !menu }"
          />
        </div>
      </div>
    </div>
    <div
      ref="container"
      data-cy="base-expansion-panel-container"
      class="tw-flex tw-flex-col"
      :style="containerStyle"
      :class="{
        'tw-h-0': !expanded,
        'tw-flex-1': !inTransition && fitContainer && expanded,
        'tw-overflow-hidden':
          inTransition || (fitContainer && !scrollContainer),
        'tw-overflow-auto': scrollContainer && expanded,
      }"
    >
      <div
        ref="content"
        data-cy="base-expansion-panel-content"
        class="tw-flex tw-flex-col"
        :class="{
          'tw-p-1':
            !noPadding && dense && (!fitContainer || scrollContainer) && !menu,
          'tw-p-4':
            !noPadding && !dense && (!fitContainer || scrollContainer) && !menu,
          'tw-flex-1': fitContainer && !scrollContainer,
          'tw-overflow-hidden': fitContainer && !scrollContainer,
        }"
      >
        <slot :expanded="expanded && !inTransition" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Model, Props } from "shared/composables/useExpansionPanel";
import useExpansionPanel from "shared/composables/useExpansionPanel";

import BaseIcon from "./BaseIcon.vue";

const modelValue = defineModel<Model>({ default: false });

const props = withDefaults(defineProps<Props>(), {
  label: "",
  caption: "",
  group: "",
  duration: 300,
});

const {
  container,
  content,
  containerStyle,
  mouseover,
  expanded,
  inTransition,
  togglePanel,
  openPanel,
  closePanel,
  onMouseOver,
  onMouseOut,
} = useExpansionPanel(modelValue, props);
</script>
