import { getCurrentInstance, inject, onUnmounted } from "vue";

import { type FormInput } from "shared/components/base/FormWrapper.vue";
import { FormWrapperSymbol } from "shared/components/base/symbols";

export function useFormChild(formChild: FormInput) {
  const Form = inject(FormWrapperSymbol, null);
  const instance = getCurrentInstance();

  if (Form && instance?.proxy) {
    const { proxy } = instance;

    const input: typeof proxy & FormInput = Object.assign(proxy, formChild);

    Form.register(input);

    onUnmounted(() => {
      Form.unregister(input);
    });
  }
}

export default useFormChild;
