import { PushNotifications } from "@capacitor/push-notifications";
import { Platform } from "quasar";

import { $streemApiV1 } from "shared/boot/api";
import { appInstance } from "shared/boot/app";
import { streamTypes } from "shared/constants";
import { social } from "shared/helpers/media";
import { safePush } from "shared/helpers/routing";
import StorageService from "shared/services/StorageService";
import { useAlertsStore } from "shared/stores/alerts";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useStreamsStore } from "shared/stores/streams";
import { useUserStore } from "shared/stores/user";

let pushNotificationToken = null;

let router = {};

let routerPush = () => {};

function goToDailyHighlights(notification) {
  const { id } = notification.data;

  routerPush(`/daily-highlights/${id}`);
}

function isMatchingCurrentStreamRoute(name, stream) {
  return (
    router.currentRoute.name !== name ||
    router.currentRoute.params?.groupSlug !== stream.group?.slug ||
    router.currentRoute.params?.streamSlug !== stream.slug
  );
}

async function maybeRouteToRelatedStream(stream) {
  await useStreamsStore().getStreams();

  const name = {
    [streamTypes.mentionStream]: "stream",
    [streamTypes.socialStream]: "social-monitor",
  }[stream.type];

  if (isMatchingCurrentStreamRoute(name, stream)) {
    routerPush({
      name,
      params: {
        groupSlug: stream.group?.slug,
        streamSlug: stream.slug,
      },
    });
  }
}

async function maybeRouteToRelatedStreamGroup(group) {
  await useStreamGroupsStore().getStreamGroups();

  const name = {
    [streamTypes.mentionStream]: "stream",
    [streamTypes.socialStream]: "social-monitor",
  }[group.stream_type];

  const stream = useStreamsStore().getStreamById(group.streams[0]?.id);
  const getStreamSlug = stream ? stream.slug : "";

  if (isMatchingCurrentStreamRoute(name, group)) {
    routerPush({
      name,
      params: {
        groupSlug: group?.slug,
        streamSlug: getStreamSlug,
      },
    });
  }
}

function categoryMatchesSocialType(category) {
  const notificationType = category.split("_mention")[0];
  const socialTypes = social.relatedFields;

  return socialTypes.some((type) => type === notificationType);
}

async function goToRelatedStream(data) {
  const streamId = data.additionalData.stream_id;

  const stream = useStreamsStore().streams.find(
    (storedStream) => storedStream.id === Number(streamId)
  );

  if (stream) {
    useMentionCountsStore().fetchMentionCounts();

    await maybeRouteToRelatedStream(stream);
  }
}

async function goToMention(notification) {
  const {
    id,
    category,
    keywords,
    product,
    stream_id: streamId,
    group_id: streamGroupId,
  } = notification.data;

  const categoriesWithRestrictions = [
    "paper_article_mention",
    "magazine_article_mention",
    "tv_caption_mention",
  ];

  let stream;
  let streamGroup;
  let streamIdParam = "";

  if (streamGroupId) {
    streamGroup = useStreamGroupsStore().streamGroups.find(
      (storedStreamGroup) => storedStreamGroup.id === Number(streamGroupId)
    );

    if (streamGroup) {
      await maybeRouteToRelatedStreamGroup(streamGroup);
    }
  }

  if (streamId) {
    stream = useStreamsStore().streams.find(
      (storedStream) => storedStream.id === Number(streamId)
    );

    // If there is a new mention, updated related stream mention count
    if (stream) {
      await maybeRouteToRelatedStream(stream);
      useMentionCountsStore().fetchMentionCounts();
      streamIdParam = `/${streamId}`;
    }
  }

  if (categoriesWithRestrictions.includes(category)) {
    routerPush({
      path: `/mention-alert/${category}/${id}/${stream ? streamId : ""}`,
      query: { keywords },
    });

    return;
  }

  if (category === "article_mention") {
    routerPush({
      path: `/articles/${id}${streamIdParam}`,
      query: { keywords },
    });

    return;
  }

  if (category === "tv_super_mention") {
    routerPush({
      path: `/tv_supers/${id}${streamIdParam}`,
      query: { keywords },
    });

    return;
  }

  if (category === "tv_logo_appearance_mention") {
    routerPush({
      path: `/tv_logo_appearances/${id}${streamIdParam}`,
      query: { keywords },
    });

    return;
  }

  if (category === "radio_clip_mention") {
    routerPush({
      path: `/radio_clips/${id}${streamIdParam}`,
      query: { keywords },
    });

    return;
  }

  if (category === "podcast_episode_mention") {
    routerPush({
      path: `/podcast_episodes/${id}${streamIdParam}`,
      query: { keywords },
    });

    return;
  }

  if (category === "tweet_mention") {
    routerPush({
      path: `/tweets/${id}${streamIdParam}?product=${product}`,
      query: { keywords },
    });

    return;
  }

  const matchingCategory = categoryMatchesSocialType(category);

  if (matchingCategory && stream) {
    await maybeRouteToRelatedStream(stream);
  }
}

async function processNotification(notification) {
  if (!useUserStore().isLoggedIn || !notification.data) return;

  if (
    notification.data.organisation_id &&
    notification.data.organisation_id !==
      useUserStore().currentUser.organisation.id
  ) {
    await StorageService.set("notificationData", JSON.stringify(notification));

    try {
      await useUserStore().switchOrganisation(
        notification.data.organisation_id,
        "mobile"
      );
    } catch {
      StorageService.remove("notificationData");
    }

    return;
  }

  const { category } = notification.data;

  switch (category) {
    case "stream": {
      await useStreamsStore().getStreams();
      goToRelatedStream(notification);
      break;
    }
    case "group": {
      await useStreamGroupsStore().getStreamGroups();
      maybeRouteToRelatedStreamGroup(notification);
      break;
    }
    case "alert": {
      await useAlertsStore().fetchAlertCount();
      routerPush({ name: "alerts" });
      break;
    }
    case "daily_highlights": {
      goToDailyHighlights(notification);
      break;
    }
    case "spike_notification": {
      await useStreamsStore().getStreams();
      goToRelatedStream(notification);
      break;
    }

    default: {
      goToMention(notification);
      break;
    }
  }
}

async function processCachedNotification() {
  try {
    const cachedNotification = JSON.parse(
      (await StorageService.get("notificationData")) || null
    );

    if (!cachedNotification || !useUserStore().isLoggedIn) {
      return await Promise.resolve();
    }

    await StorageService.remove("notificationData");

    if (
      cachedNotification.data.organisation_id !==
      useUserStore().currentUser.organisation.id
    ) {
      return await Promise.resolve();
    }

    return await processNotification(cachedNotification);
  } catch {
    return StorageService.remove("notificationData");
  }
}

function tryToSendTokenToAPI() {
  if (!pushNotificationToken || !useUserStore().isLoggedIn) {
    return;
  }

  $streemApiV1.post("devices", {
    params: {
      token: pushNotificationToken,
      platform: Platform.is.ios ? "ios" : "android",
      app: "streem",
    },
  });
}

async function on() {
  tryToSendTokenToAPI();

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (pushNotification) => {
      let notification = null;

      try {
        notification = JSON.parse(pushNotification.notification.data.payload);
      } catch {
        // iOS notifications come already parsed
        notification = pushNotification.notification.data.payload;
      }

      processNotification(notification);
    }
  );
}

function stop() {
  PushNotifications.removeAllListeners();
}

async function start() {
  router = appInstance.config.globalProperties.$router;
  routerPush = safePush({ router });

  let permissionStatus = await PushNotifications.checkPermissions();

  if (permissionStatus.receive === "prompt") {
    permissionStatus = await PushNotifications.requestPermissions();
  }

  if (permissionStatus.receive !== "granted") {
    return;
  }

  await PushNotifications.register();

  await PushNotifications.addListener("registration", (token) => {
    pushNotificationToken = token.value;

    // A new FCM token has been defined, we have to send it to the API.
    // Since the token is associated with an user, we only can send it when the user is logged in
    // So if they are logged in, we send it now, otherwise we keep it and will send it after login
    tryToSendTokenToAPI();
  });

  on();
}

export default {
  processCachedNotification,
  start,
  stop,
};
