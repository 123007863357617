export default () => ({
  focusedStream: null,
  focusedStreamTimeout: null,
  focusedBookmarkStream: null,
  focusedSocialStream: null,
  focusedBookmarkStreamTimeout: null,
  focusedSocialStreamTimeout: null,
  organisationReportSpecsOpen: false,
  organisationReportSpec: null,
  organisationReportSpecs: [],
  addedOrganisationReportMention: null,
});
