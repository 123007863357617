import chroma from "chroma-js";
import {
  computed,
  getCurrentInstance,
  isRef,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";

import { getLocaleText } from "shared/boot/i18n";
import useFormChild from "shared/composables/useFormChild";
import {
  REGEXP_DOMAIN,
  REGEXP_EMAIL,
  REGEXP_NO_SPECIAL_CHARACTERS,
  REGEXP_PHONE_NUMBER,
  REGEXP_URL,
} from "shared/constants";
import { prettyNumber } from "shared/helpers/number";
import { countWords, isValidHTMLContent } from "shared/helpers/string";

export type ValidationResult = boolean | string;

export type ValidatorFunction<T = any> = (
  value: T
) => ValidationResult | Promise<ValidationResult>;

export type ValidatorFactory<T = any, U = any> = (
  value: T
) => ValidatorFunction<U>;

export const notBlank: ValidatorFunction = (input) =>
  (input &&
    ((typeof input === "string" && Boolean(input.trim().length)) ||
      (Array.isArray(input) && Boolean(input.length)) ||
      (typeof input === "object" && Boolean(Object.keys(input).length)) ||
      typeof input === "number")) ||
  getLocaleText("validations.not_blank");

export const isEmail: ValidatorFunction = (input) =>
  REGEXP_EMAIL.test(input) || getLocaleText("validations.is_email");

export const isValidDomain: ValidatorFunction = (input) =>
  REGEXP_DOMAIN.test(input) || getLocaleText("validations.is_valid_domain");

export const noSpecialCharacters: ValidatorFunction = (input) =>
  REGEXP_NO_SPECIAL_CHARACTERS.test(input) ||
  getLocaleText("validations.no_special_characters");

export const isPhoneNumber: ValidatorFunction = (input) =>
  REGEXP_PHONE_NUMBER.test(input) ||
  getLocaleText("validations.is_phone_number");

export const isColor: ValidatorFunction = (input) =>
  !input || chroma.valid(input) || getLocaleText("validations.is_color");

export const isUrl: ValidatorFunction = (input) =>
  (typeof input === "string" && REGEXP_URL.test(input)) ||
  getLocaleText("validations.is_url");

export const isNumber: ValidatorFunction = (input) =>
  !Number.isNaN(Number(input)) || getLocaleText("validations.is_number");

export const hasHTMLContent: ValidatorFunction = (input) =>
  isValidHTMLContent(input) || getLocaleText("validations.not_blank");

export const maximumLength: ValidatorFactory<number, string> =
  (maxCharacters) => (input) =>
    !input ||
    input.length <= maxCharacters ||
    getLocaleText("validations.maximum_length", { maxCharacters });

export const maximumNumber: ValidatorFactory<number, number> =
  (maxNumber) => (input) =>
    input <= maxNumber ||
    getLocaleText("validations.maximum_number", {
      maximumNumber: prettyNumber(maxNumber),
    });

export const minimumImageSize: ValidatorFactory<
  { minWidth: number; minHeight: number },
  string
> = (args) => async (input) => {
  const { minWidth, minHeight } = args;

  if (!input) {
    return false;
  }

  let result = null;

  try {
    const image = new Image();
    image.src = input;

    await image.decode();

    let isValid = false;
    let errorMessage = "";

    if (minWidth && minHeight) {
      isValid =
        image.naturalWidth >= minWidth && image.naturalHeight >= minHeight;

      errorMessage = getLocaleText(
        "validations.minimum_image_size.min_width_min_height",
        { minWidth, minHeight }
      );
    } else if (minWidth) {
      isValid = image.naturalWidth >= minWidth;

      errorMessage = getLocaleText("validations.minimum_image_size.min_width", {
        minWidth,
      });
    }

    result = isValid || errorMessage;
  } catch (error) {
    result = getLocaleText("validations.minimum_image_size.load_error");
  }

  return result;
};

export const minimumWords: ValidatorFactory<number, string> =
  (minWords) => (input) => {
    if (!input) {
      return true;
    }

    const wordCount = countWords(input);

    if (wordCount >= minWords) {
      return true;
    }

    return getLocaleText("input_validation.minimum_words_required", {
      count: minWords,
    });
  };

export const validEmailDomain: ValidatorFactory<string, string> =
  (domain) => (input) => {
    if (!input) {
      return true;
    }

    const domainPattern = new RegExp(`@${domain}$`);

    if (domainPattern.test(input)) {
      return true;
    }

    return getLocaleText("validations.is_valid_email_domain");
  };

export const validatorFunctions = {
  isEmail,
  isPhoneNumber,
  isColor,
  isUrl,
  notBlank,
  isNumber,
  isValidDomain,
  hasHTMLContent,
  noSpecialCharacters,
} as const;

export const validatorFactoryFunctions = {
  maximumLength,
  maximumNumber,
  minimumImageSize,
  minimumWords,
  validEmailDomain,
} as const;

export type ValidatorFunctionNames = keyof typeof validatorFunctions;
export type Validations = (ValidatorFunctionNames | ValidatorFunction)[];

export interface UseValidationOptions {
  modelValue: any;
  validations: Validations;
  optional?: boolean;
}

export default function useValidation(options: UseValidationOptions) {
  const { emit } = getCurrentInstance()!;

  const modelValue = isRef(options.modelValue)
    ? options.modelValue
    : toRef(options, "modelValue");

  const validations = isRef(options.validations)
    ? (options.validations as Ref<Validations>)
    : toRef(options, "validations");

  const optional = isRef(options.optional)
    ? options.optional
    : toRef(options, "optional");

  const input = computed({
    get() {
      return modelValue.value;
    },
    set(newValue) {
      emit("update:modelValue", newValue);
    },
  });

  const dirty = ref(false);
  const errors = ref<string[]>([]);
  const isValid = computed<boolean>(() => !errors.value.length);

  const validatorFns = computed<ValidatorFunction[]>(() =>
    validations.value.map((validator) =>
      typeof validator === "string" ? validatorFunctions[validator] : validator
    )
  );

  async function validate(): Promise<boolean> {
    if (optional?.value && typeof notBlank(input.value) === "string") {
      errors.value = [];
    } else {
      const validationResults = await Promise.all(
        validatorFns.value.map((validator) => validator(input.value))
      );

      const errorMessages = validationResults.filter(
        (result): result is string => typeof result === "string"
      );

      errors.value = errorMessages;
    }

    return isValid.value;
  }

  function clearValue(): void {
    const type = typeof input.value;

    if (Array.isArray(input.value)) {
      input.value = [];
    } else if (type === "string") {
      input.value = "";
    } else if (type === "boolean") {
      input.value = false;
    } else {
      input.value = undefined;
    }
  }

  function clearValidations(): void {
    errors.value = [];
    dirty.value = false;
  }

  function reset(): void {
    clearValue();
    clearValidations();
  }

  async function onInputChange(newValue: any): Promise<void> {
    if (newValue) dirty.value = true;
    await validate();
  }

  watch(input, onInputChange);
  watch(modelValue, onInputChange);

  watch(validations, (newVal) => {
    if (newVal?.length === 0) {
      clearValidations();
    } else {
      validate();
    }
  });

  useFormChild({ validate, reset, dirty });

  return {
    input,
    dirty,
    errors,
    isValid,
    isValidDomain,
    clearValue,
    clearValidations,
    reset,
    validate,
  };
}
