<template>
  <div>
    <div class="row">
      <div class="row full-width items-center">
        <div class="col-auto">
          <QIcon
            name="ion-md-megaphone"
            class="contact-icon"
            size="14px"
          />
        </div>

        <div
          :class="$isMobile ? 'q-ml-xs' : 'q-ml-sm'"
          class="col"
        >
          <OutreachRecipientListDropdown
            :selected-lists="selectedLists"
            :disabled="disabledForOutreach"
            @add="addRecipientToList"
          />

          <span
            v-if="disabledForOutreach"
            class="text-help-text text-error"
          >
            {{
              $t(
                "journalist_mention_card_outreach_actions.cannot_add_to_outreach_list"
              )
            }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="selectedLists.length"
      :class="$isMobile ? 'q-ml-md' : 'q-ml-lg'"
      class="row q-mt-sm q-gutter-xs"
    >
      <div
        v-for="list in selectedLists"
        :key="list.id"
        class="tag flex items-center text-help-text"
      >
        <RouterLink
          :to="outreachListUrl(list)"
          class="text-white hover-active"
        >
          {{ list.name }}
        </RouterLink>

        <QIcon
          name="close"
          color="white"
          size="13px"
          class="q-ml-xs cursor-pointer"
          @click="removeFromList(list)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OutreachRecipientListDropdown from "shared/components/OutreachRecipientListDropdown.vue";
import {
  OutreachRecipientList,
  OutreachRecipientListRecipient,
} from "shared/resources";

export default {
  name: "JournalistMentionCardOutreachActions",
  components: {
    OutreachRecipientListDropdown,
  },
  props: {
    journalist: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLists: [],
    };
  },
  computed: {
    disabledForOutreach() {
      return !this.journalist.email || !this.journalist.email.length;
    },
    hasAlternateEmails() {
      return this.journalist.alternate_emails?.length;
    },
  },
  mounted() {
    this.selectedLists = this.journalist.outreach_recipient_lists;
  },
  methods: {
    outreachListUrl(list) {
      return {
        name: "outreach-recipient-lists",
        query: {
          id: list.id,
          ts: Number(new Date()),
        },
      };
    },
    async addRecipientToList(list) {
      try {
        await this.addRecipients(list);

        this.selectedLists.push({
          id: Number(list.id),
          name: list.name,
          recipient_id: Number(list.recipients[0].id),
          recipient_name: list.recipients[0].name,
        });
      } catch {
        this.$q.notify({
          type: "negative",
          message: this.$t(
            "journalist_mention_card_outreach_actions.create_recipient_list_error"
          ),
        });
      }
    },
    addToList(list, emails) {
      emails.forEach((email) => {
        list.recipients.push(
          new OutreachRecipientListRecipient({
            email,
            name: this.journalist.name,
            journalistId: this.journalist.id,
          })
        );
      });

      return list.saveResource({ with: "recipients" });
    },
    removeFromList(list) {
      const graphitizedOutreachRecipientList = new OutreachRecipientList({
        id: list.id,
        recipients: [],
      });

      const removedJournalist = new OutreachRecipientListRecipient({
        id: list.recipient_id,
      });

      // marking graphiti objects as persisted ensure updates use PATCH not POST
      graphitizedOutreachRecipientList.isPersisted = true;
      removedJournalist.isPersisted = true;
      removedJournalist.isMarkedForDestruction = true;
      graphitizedOutreachRecipientList.recipients.push(removedJournalist);

      return graphitizedOutreachRecipientList
        .saveResource({ with: "recipients" })
        .then(() => {
          this.selectedLists = this.selectedLists.filter(
            (selectedList) => Number(selectedList.id) !== Number(list.id)
          );
        });
    },
    async addRecipients(list) {
      if (this.hasAlternateEmails) {
        this.$modals.open("SelectOutreachRecipientsModal", {
          props: {
            journalist: this.journalist,
            list,
          },
          events: {
            cancel: () => {
              this.$modals.close("SelectOutreachRecipientsModal");
              this.removeSelectedList(list);
            },
            addEmailsToList: async (emails) => {
              this.$modals.close("SelectOutreachRecipientsModal");
              await this.addToList(list, emails);
            },
          },
        });
      } else {
        await this.addToList(list, [this.journalist.email]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-icon {
  color: #9b9b9b;
}

.tag {
  background: $primary;
  color: $white;
  padding: 4px 8px;
  border-radius: 26.4583px;
}
</style>
