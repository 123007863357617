import { MINUTE_IN_MILLISECONDS } from "shared/constants";

export default function Poller({
  callback = () => {},
  interval = MINUTE_IN_MILLISECONDS,
  pauseBackgroundTabs = false,
}) {
  let timeout = null;
  let lastRunAt = null;
  let initialized = false;

  async function executeCallback() {
    await callback();
    lastRunAt = Date.now();
  }

  const changePollerStateOnVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (lastRunAt + interval < Date.now()) {
        this.run();
      } else {
        this.start();
      }
    } else {
      this.pause();
    }
  };

  this.init = ({ immediate } = {}) => {
    if (initialized) return;

    this.start({ immediate });

    // Pause the polling when app is in background
    document.addEventListener("pause", this.pause, false);
    document.addEventListener("resume", this.start, false);

    // Desktop - Pause polling when tab is not active
    if (pauseBackgroundTabs) {
      document.addEventListener(
        "visibilitychange",
        changePollerStateOnVisibilityChange,
        false
      );
    }

    initialized = true;
  };

  this.start = async ({ immediate } = {}) => {
    if (timeout) this.pause();
    if (immediate) await executeCallback();

    timeout = setTimeout(() => this.run(), interval);
  };

  this.pause = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  this.stop = () => {
    document.removeEventListener("pause", this.pause, false);
    document.removeEventListener("resume", this.start, false);

    // Desktop - Pause polling when tab is not active
    document.removeEventListener(
      "visibilitychange",
      changePollerStateOnVisibilityChange,
      false
    );

    this.pause();
    initialized = false;
  };

  this.run = async () => {
    await executeCallback();
    this.start();
  };
}
