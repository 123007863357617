import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useSyndicationStore = defineStore("syndication", {
  state,
  getters,
  actions,
});

export default useSyndicationStore;
