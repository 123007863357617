import { computed, ref, toRef } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { toSentence } from "shared/helpers/array";
import { getMediaForStream } from "shared/helpers/media";
import StreamFilters from "shared/helpers/StreamFilters";
import { useStreamsStore } from "shared/stores/streams";
import type { Nullable, Stream } from "shared/types";

interface UseStreamFilteringProps {
  stream: Stream;
  filters?: StreamFilters;
}

export default function useStreamFiltering(options: UseStreamFilteringProps) {
  const stream = toRef<Stream>(options.stream);

  const filters = ref<StreamFilters>(
    options.filters || new StreamFilters(stream.value)
  );

  const { getStreamFilters } = useStreamsStore();

  const streamFilters = getStreamFilters(Number(stream.value.id));

  if (streamFilters) {
    filters.value = streamFilters;
  }

  const selectorActive = ref<Nullable<string>>(null);

  const filtering = computed(() =>
    Boolean(
      filters.value.filteringContent() ||
        filters.value.filteringReactScore() ||
        filters.value.filteringLocations() ||
        filters.value.filteringMedia(
          getMediaForStream(stream.value as Stream)
        ) ||
        filters.value.filteringPlatforms() ||
        filters.value.filteringHandleOrSocialImpact() ||
        filters.value.filteringSources() ||
        filters.value.filteringTags()
    )
  );

  const filterLabels = computed(() => {
    const labels = [];

    if (filters.value.filteringAuthors()) {
      let label = getLocaleText("stream_filtering.author");

      if (stream.value.type === streamTypes.socialStream) {
        label += getLocaleText("stream_filtering.handle");
      }

      labels.push(label);
    }

    if (filters.value.filteringContent()) {
      labels.push(getLocaleText("stream_filtering.content"));
    }

    if (filters.value.filteringReactScore()) {
      labels.push(getLocaleText("stream_filtering.react_score"));
    }

    if (filters.value.filteringLocations()) {
      labels.push(getLocaleText("stream_filtering.locations"));
    }

    if (
      filters.value.filteringMedia(getMediaForStream(stream.value as Stream))
    ) {
      labels.push(getLocaleText("stream_filtering.media"));
    }

    if (filters.value.filteringPlatforms()) {
      labels.push(getLocaleText("stream_filtering.social_platforms"));
    }

    if (filters.value.filteringSources()) {
      labels.push(getLocaleText("stream_filtering.sources"));
    }

    if (filters.value.filteringTags()) {
      labels.push(getLocaleText("stream_filtering.tags"));
    }

    return labels;
  });

  const filteringLabel = computed(() =>
    getLocaleText("stream_filtering.filtering_by", {
      category: toSentence(filterLabels.value),
    })
  );

  const filtersSelectorActive = computed({
    get() {
      return selectorActive.value === "filters";
    },
    set(value) {
      if (!value) selectorActive.value = null;
      selectorActive.value = "filters";
    },
  });

  const displayFiltersSelector = () => {
    selectorActive.value = "filters";
  };

  const toggleDisplayFiltersSelector = () => {
    selectorActive.value = filtersSelectorActive.value ? null : "filters";
  };

  return {
    filters,
    filtering,
    filterLabels,
    filteringLabel,
    selectorActive,
    filtersSelectorActive,
    displayFiltersSelector,
    toggleDisplayFiltersSelector,
  };
}
