<template>
  <div class="row justify-center items-center full-width translation-loader">
    <div class="column items-center">
      <QSpinnerDots
        size="53px"
        color="secondary"
      />
      <div class="translation-loader-description">
        {{ $t("translation_loader.translating_mention") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.translation-loader {
  min-height: 100px;

  &-description {
    color: var(--s-color-denim-8);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
