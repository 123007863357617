<template>
  <div
    v-if="show"
    ref="root"
    class="list menu-shadow-box rounded-borders"
    data-testid="list"
  >
    <slot name="beforeOptions" />
    <slot
      name="list-body"
      :select="select"
    >
      <div
        ref="listBody"
        class="list-body"
        data-testid="listBody"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="{
            active: index === optionIndex,
            disabled: isOptionDisabled(option),
          }"
          :data-testid="optionTestId(option, index)"
          tabIndex="-1"
          class="outline-none"
          @mouseover="optionIndex = index"
          @click.left.stop.prevent="select(option)"
        >
          <slot
            name="option"
            :option="option"
            :selected="isSelected(option)"
            :disabled="isOptionDisabled(option)"
            :active="index === optionIndex"
          >
            <div
              :class="{
                active: index === optionIndex,
                disabled: isOptionDisabled(option),
                selected: isSelected(option),
              }"
              class="full-width list-option flex justify-between"
            >
              {{ displayOption(option) }}
            </div>
          </slot>
        </div>
      </div>
    </slot>

    <slot
      v-if="loading"
      name="loading"
    >
      <span>
        <QSpinner />
      </span>
    </slot>

    <slot
      v-if="!options.length"
      name="empty"
    >
      <div>
        <div
          disabled
          class="list-option flex justify-between"
        >
          {{ $t("autocomplete_list.no_available_options") }}
        </div>
      </div>
    </slot>

    <slot name="afterOptions" />
  </div>
</template>

<script lang="ts">
export interface AutoCompleteOption {
  [key: string]: any;
  isGroup?: boolean;
  disabled?: boolean;
  testId?: string;
}

export type GenericOption = AutoCompleteOption | string | number;

export function isOptionObject(
  option: GenericOption | null
): option is AutoCompleteOption {
  return typeof option === "object";
}
</script>

<script setup lang="ts" generic="T extends GenericOption">
import { nextTick, onBeforeUnmount, ref, watch } from "vue";

export interface AutocompleteListProps<OptionType> {
  options: OptionType[];
  selection?: OptionType | OptionType[] | null;
  optionProp?: string | null;
  optionLabel?: string | ((option: OptionType) => string) | null;
  multiple?: boolean;
  loading?: boolean;
}

enum Direction {
  Forward,
  Backward,
}

const props = withDefaults(defineProps<AutocompleteListProps<T>>(), {
  options: () => [],
  selection: null,
  optionProp: null,
  optionLabel: null,
});

const show = defineModel<boolean>("show", {
  required: true,
});

const emit = defineEmits<{
  select: [T];
}>();

const optionIndex = ref(-1);
const root = ref<HTMLElement | null>(null);
const listBody = ref<HTMLElement | null>(null);

function findValidSelection(
  startingIndex: number,
  direction: Direction
): number {
  const step = direction === Direction.Forward ? 1 : -1;
  let index = startingIndex + step;

  while (index >= 0 && index < props.options.length) {
    const option = props.options[index];
    const isGroup = isOptionObject(option) && option?.isGroup;

    if (!isGroup) {
      return index;
    }

    index += step;
  }

  return startingIndex;
}

function hide(): void {
  show.value = false;
}

function scrollToActive(): void {
  if (!root.value || !listBody.value) {
    return;
  }

  const activeOption = root.value?.querySelector<HTMLDivElement>(
    ".list-option.active"
  );

  if (activeOption) {
    const extraScroll = 2 * activeOption.offsetHeight;

    const scrollHeight =
      activeOption.offsetTop - listBody.value.clientHeight + extraScroll;

    listBody.value.scrollTo(0, scrollHeight);
  }
}

function isOptionDisabled(option: T): boolean {
  return Boolean(isOptionObject(option) && option.disabled);
}

function optionTestId(option: T, index: number): string {
  return isOptionObject(option) && option?.testId
    ? option.testId
    : `listOption${index}`;
}

function select(option: T): void {
  if (isOptionDisabled(option) || (isOptionObject(option) && option?.isGroup))
    return;

  emit("select", option);
}

function handleKeypress(event: KeyboardEvent): void {
  const { key } = event;

  if (["ArrowDown", "ArrowUp"].includes(key)) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (key === "ArrowDown") {
    optionIndex.value = findValidSelection(
      optionIndex.value,
      Direction.Forward
    );
  }

  if (key === "ArrowUp") {
    optionIndex.value = findValidSelection(
      optionIndex.value,
      Direction.Backward
    );
  }

  if (key === "Enter") {
    if (props.options[optionIndex.value]) {
      event.preventDefault();
      event.stopPropagation();
      select(props.options[optionIndex.value]);
    }
  }

  if (key === "Escape") {
    hide();

    return;
  }

  scrollToActive();

  if (optionIndex.value < 0) {
    optionIndex.value = 0;
  } else if (optionIndex.value > props.options.length - 1) {
    optionIndex.value = props.options.length - 1;
  }
}

function toggleKeyboardListener(method: "add" | "remove" = "add"): void {
  if (method === "add") {
    window.addEventListener("keydown", handleKeypress, true);
  } else {
    window.removeEventListener("keydown", handleKeypress, true);
  }
}

function displayOption(option: T): string {
  const isObject = isOptionObject(option);

  if (!isObject) {
    return option.toString();
  }

  if (props.optionLabel) {
    if (typeof props.optionLabel === "function") {
      return props.optionLabel(option);
    }

    return option[props.optionLabel];
  }

  return "";
}

function isSelected(option: T): boolean {
  if (!props.selection) return false;

  const optionValue =
    isOptionObject(option) && props.optionProp
      ? option[props.optionProp]
      : option;

  if (!props.multiple || !Array.isArray(props.selection)) {
    return optionValue === props.selection;
  }

  return props.selection.some((opt) => {
    if (isOptionObject(opt) && props.optionProp) {
      return opt[props.optionProp] === optionValue;
    }

    return JSON.stringify(opt) === JSON.stringify(option);
  });
}

async function scrollToSelected(): Promise<void> {
  if (!root.value || !listBody.value) return;

  const selectedOption = root.value?.querySelector<HTMLElement>(
    ".list-option.selected"
  );

  if (selectedOption) {
    await nextTick();

    const extraScroll = 2 * selectedOption.offsetHeight;

    const scrollHeight =
      selectedOption.offsetTop - listBody.value.clientHeight + extraScroll;

    listBody.value.scrollTo(0, scrollHeight);
  }
}

watch(
  show,
  (value) => {
    if (value) {
      optionIndex.value = -1;

      if (props.selection) {
        nextTick(scrollToSelected);
      }
    }

    toggleKeyboardListener(value ? "add" : "remove");
  },
  { immediate: true }
);

watch(
  () => props.options,
  () => {
    optionIndex.value = -1;
  },
  { deep: true }
);

onBeforeUnmount(() => {
  toggleKeyboardListener("remove");
});

defineExpose({
  optionIndex,
});
</script>

<style lang="scss" scoped>
.outline-none:focus {
  outline: none;
}

.list {
  background-color: var(--s-card-bg);

  .list-body {
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

:deep(.list-option) {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;

  &:hover,
  &.active,
  &:focus {
    background: var(--s-color-denim-1);
    outline: none;
  }

  &.group-option {
    padding: 8px;
    border-top: 1px solid var(--s-color-denim-2);
  }

  &.group {
    padding: 8px;
    font-weight: bold;
    pointer-events: none;
  }

  &.selected {
    background-color: var(--s-color-denim-2);
    outline: none;
  }

  .list-option-action {
    color: $white;
  }
}
</style>
