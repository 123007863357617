<template>
  <slot />
</template>

<script>
export default {
  name: "VFragment",
  inheritAttrs: false,
};
</script>
