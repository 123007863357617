<template>
  <div class="pagination row justify-between q-py-sm">
    <div>
      <a
        v-if="page > 1 && totalPages > 1"
        class="previous hover-active pointer"
        @click="$emit('previous')"
      >
        <QIcon
          name="keyboard_arrow_left"
          size="16px"
        />
        {{ $t("global.previous") }}
      </a>
    </div>

    {{
      $t("pagination.page_of_total", {
        page,
        totalPages: prettyNumber(totalPages),
      })
    }}

    <div>
      <a
        v-if="page < totalPages"
        class="next hover-active pointer"
        @click="$emit('next')"
      >
        {{ $t("global.next") }}
        <QIcon
          name="keyboard_arrow_right"
          size="16px"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { prettyNumber } from "shared/helpers/number";

export default {
  name: "Pagination",
  props: {
    page: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  emits: ["previous", "next"],
  methods: {
    prettyNumber,
  },
};
</script>
