export default {
  addNotification(notification) {
    if (this.notifications.length === 0) {
      this.setCurrentNotification(notification);
    }

    this.notifications.unshift(notification);
  },
  popNotification() {
    this.notifications.pop();
    const notificationsLength = this.notifications.length;

    if (notificationsLength > 0) {
      this.setCurrentNotification(this.notifications[notificationsLength - 1]);
    } else {
      this.setCurrentNotification(null);
    }
  },
  clearNotificationsQueue() {
    this.notifications = [];
    this.setCurrentNotification(null);
  },
  updateKeyboardOpen(open) {
    this.keyboardOpen = open;
  },
  setKeyboardHeight(height) {
    this.keyboardHeight = height;
  },
  setSelectedMention(mention) {
    this.selectedMention = mention;
  },
  setContainerOffset(offset) {
    this.containerOffset = offset;
  },
  setCurrentNotification(notification) {
    this.currentNotification = notification;
  },
  setOrientationAngle(angle) {
    this.orientationAngle = angle;
  },
};
