<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      :title="mention.title"
      :list-title="listTitle"
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :stream="stream"
      :action-sheet-component="actionSheetComponent"
      :options="options"
      :no-highlighting="noHighlighting"
      :in-mention-preview-alert="inMentionPreviewAlert"
      :show-checkbox="showCheckbox"
      :always-show-keywords="alwaysShowKeywords"
      :selected="selected"
      class="pointer"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #before="{ hideBorderTop }">
        <div
          v-if="mention.thumbnails && mention.thumbnails.length"
          class="row justify-center full-width"
          @click.stop
        >
          <div
            v-if="clip.media_url"
            class="full-width"
          >
            <PlayerPopoutTVCardControl
              v-show="showTVCardControl"
              :preview-image-url="mention.thumbnails[0].url"
              :clip="clip"
              :hide-border-top="hideBorderTop"
              @play="playInPopout"
            />

            <PortalTarget :name="`tv-card-${uid}`" />
          </div>
        </div>
      </template>

      <template #header>
        <span
          v-if="mention.syndications && mention.syndications.length > 0"
          class="alternate q-mt-xs"
          @click.stop="displaySyndication = !displaySyndication"
        >
          <strong class="bigger2">
            {{ mention.source.name }}
          </strong>
          {{ mention.source.location }}
          <span>+{{ mention.syndications.length }}</span>
        </span>
        <span
          v-else
          class="alternate q-mt-xs"
        >
          <strong class="bigger2">
            {{ mention.source.name }}
          </strong>
          {{ mention.source.location }}
        </span>
        <span class="softer">
          <TimeAgo
            :date="mention.timestamp"
            :timezone="mention.source.time_zone"
          />
          <span v-if="mention.program_airing">
            · {{ mention.program_airing.name }}
          </span>
          <span
            v-if="$isDesktop && hasTranscript"
            @click.stop="openViewTranscriptModal"
          >
            {{ $t("tv_mention_card.view_transcript") }}
          </span>
        </span>
      </template>

      <slot />

      <template #beforeBody>
        <Syndications
          :expanded="displaySyndication"
          :mention="mention"
          :stream="stream"
          @syndication-clicked="syndicationClicked($event)"
        />
      </template>

      <template #afterBody>
        <div class="row soft q-mt-md items-center q-gutter-x-sm">
          <BroadcastAudienceWidget
            :mention="mention"
            medium="tv"
          />
          <AdvertisingValueWidget :mention="mention" />
          <SentimentWidget
            :mention="mention"
            :stream="stream"
          />
          <ReactScoreWidget
            class="soft"
            :mention="mention"
            :is-parent-visible="isIntersecting"
          />
        </div>
        <UniversalPlayerControlWidget
          v-if="clip.media_url && alwaysPlayInPopout"
          :clip="clip"
          class="play-button q-mt-md q-mb-md"
          @click.stop
          @play="playInPoppedOutPlayer"
        />
      </template>

      <template #after>
        <!-- Syndication for Captions and Tv Super -->
        <div
          v-if="mention.similarMentions && mention.similarMentions.length"
          class="card syndication-card pointer hide-radius-top"
        >
          <div
            v-for="(syndication, key) in mention.similarMentions"
            :key="key"
            :class="'border-color-' + medium"
            class="card-caption mention-content hide-radius-top"
          >
            <div
              class="row no-wrap items-center q-py-smd"
              @click="syndicationClicked(syndication)"
            >
              <span class="bold q-mr-xs"> {{ syndication.source.name }} </span
              >&nbsp;
              <span>
                {{ syndication.source.location }}
                {{
                  syndication.program_airing
                    ? " - " + syndication.program_airing.name
                    : ""
                }}
              </span>
              <span class="muted syndication-time q-pr-smd">
                <TimeAgo
                  :date="syndication.start_time"
                  :timezone="mention.time_zone"
                />
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="!mention.datahub && clip.media_url"
          :clip="clip"
          class="play-button"
          @click.stop
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import BroadcastAudienceWidget from "shared/components/BroadcastAudienceWidget.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import PlayerPopoutTVCardControl from "shared/components/players/PlayerPopoutTVCardControl.vue";
import UniversalPlayerControlWidget from "shared/components/players/UniversalPlayerControlWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import Syndications from "shared/components/Syndications.vue";
import useMention from "shared/composables/useMention";
import { NEXT_CLIP_OFFSET, POPOUT_PLAYER_TARGET } from "shared/constants";
import generateId from "shared/helpers/generateId";
import { mentionKeywords } from "shared/helpers/mentions";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "TVMentionCard",
  components: {
    PortalTarget,
    MentionCard,
    AdvertisingValueWidget,
    BroadcastAudienceWidget,
    PlayerPopoutTVCardControl,
    UniversalPlayerControlWidget,
    Syndications,
    ReactScoreWidget,
    SentimentWidget,
  },
  props: {
    ...propsExcept(["listTitle", "keywords"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    const mentionCountsStore = useMentionCountsStore();
    const { mentionCount } = storeToRefs(mentionCountsStore);

    const universalPlayerStore = useUniversalPlayerStore();

    const {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsPlaying,
      playerList,
      playerIsDismissed,
    } = storeToRefs(universalPlayerStore);

    const {
      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,
    } = universalPlayerStore;

    return {
      mentionClicked,
      mentionCount,

      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsPlaying,
      playerList,
      playerIsDismissed,

      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,
    };
  },
  data() {
    return {
      fragments: [],
      uid: generateId(),
      displaySyndication: false,
      clip: { media_url: "", start_time: 0 },
      showTVCardControl: true,
      isIntersecting: false,
    };
  },
  computed: {
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
    medium() {
      return this.mention.medium?.toLowerCase();
    },
    keywords() {
      return mentionKeywords(this.mention);
    },
    hasTranscript() {
      if (this.mention.tveyes_player_url?.length || this.mention.datahub) {
        return false;
      }

      return ["tv_super", "tv_caption"].includes(this.mention.type);
    },
    firstMentionTime() {
      const index = this.fragments.findIndex(
        (fragment) => fragment.text.search(this.keywords[0]) !== -1
      );

      return index !== -1
        ? this.fragments[index].timestamp
        : this.mention.start_time;
    },
    fragmentParams() {
      return {
        after: this.mention.start_time,
        before: this.mention.end_time + NEXT_CLIP_OFFSET,
        limit: 20,
      };
    },
    inPlaylist() {
      return this.playerList.some((listItem) => listItem.id === this.clip.id);
    },
  },
  beforeUnmount() {
    if (
      this.isPopoutPlayerPlayingThisClip() &&
      this.playerOpen &&
      this.$isDesktop
    ) {
      this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

      this.$track("Playing the clip in the popout player", {
        type: "tv",
      });
    }
  },
  mounted() {
    this.loadMedia();
  },
  methods: {
    openViewTranscriptModal() {
      this.$track("Opened View Transcript Modal", {
        id: this.mention.id,
        type: this.mention.type,
        streamId: this.stream?.id,
      });

      this.$modals.open("ViewTranscriptModal", {
        props: {
          mention: this.mention,
          keywords: this.keywords,
        },
      });
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    async loadClips() {
      // fetching the 30-s clip starting between 30 seconds excluded before first
      // mention  until first mention time included
      return this.$streemApiV1.get(
        `tv_channels/${this.mention.source.id}/clips`,
        {
          params: {
            after: this.mention.start_time - 15,
            before: this.mention.end_time + 15,
            limit: 3,
          },
        }
      );
    },
    async loadFragments(fragmentParams) {
      const type =
        this.mention.type === "tv_logo_appearance"
          ? "tv_caption"
          : this.mention.type;

      return this.$streemApiV1.get(
        `tv_channels/${this.mention.source.id}/${type}_fragments`,
        {
          params: fragmentParams,
        }
      );
    },
    async loadMedia() {
      if (this.mention.datahub) {
        return;
      }

      const fragments = (await this.loadFragments(this.fragmentParams)).data;
      this.fragments = fragments || [];

      const clips = (await this.loadClips()).data;

      if (clips.length) {
        const clipContainingFirstMention = clips.filter(
          (clip) =>
            clip.start_time <= this.firstMentionTime &&
            clip.end_time >= this.firstMentionTime
        );

        this.clip = clipContainingFirstMention[0] || clips[0];

        this.clip = {
          ...this.clip,
          mentionId: this.mention.id,
          program_airing: this.mention.program_airing,
          source: this.mention.source,
          type: this.mention.type,
          excerpts: this.mention.excerpts,
          keywords: this.mention.keywords,
        };
      }
    },
    handleIntersection(el) {
      this.isIntersecting = el.isIntersecting;

      if (this.playerClosed()) {
        this.showTVCardControl = true;

        return;
      }

      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget();
    },
    playInPopout() {
      this.showTVCardControl = false;
      if (!this.inPlaylist) this.playerInsertClip(this.clip);
      if (!this.playerOpen) this.playerShow();

      this.$nextTick(() => {
        this.playerPopoutRef.playNow(this.clip);
      });

      this.setPlayerTarget();
      this.setPlayerDismissed(false);
    },
    setPlayerTarget() {
      if (this.playInCard()) {
        this.setPlayerPopoutTarget(`tv-card-${this.uid}`);
        this.showTVCardControl = false;

        this.$track("Playing the clip in the card", {
          type: "tv",
        });
      } else {
        this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);
        this.showTVCardControl = true;

        this.$track("Playing the clip in the popout player", {
          type: "tv",
        });
      }
    },
    playInPoppedOutPlayer() {
      if (!this.inPlaylist) this.playerInsertClip(this.clip);
      if (!this.playerOpen) this.playerShow();

      this.$nextTick(() => {
        this.playerPopoutRef.playNow(this.clip);
      });

      this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);
      this.setPlayerDismissed(false);

      this.$track("Playing the clip in the popout player", {
        type: "tv",
      });
    },
    playInCard() {
      return (this.isIntersecting && !this.isListView()) || this.$isMobile;
    },
    isPopoutPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.clip &&
        this.playerInitialClip.id === this.clip.id
      );
    },
    isListView() {
      return this.options?.list;
    },
    playerClosed() {
      return (
        !this.isPopoutPlayerPlayingThisClip() ||
        this.playerIsDismissed ||
        this.alwaysPlayInPopout
      );
    },
    syndicationClicked(syndication) {
      this.mentionClicked({ ...syndication, type: this.mention.type });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-caption {
  padding-left: 20px;
  border-radius: 4px;
}
</style>
