import { isObject } from "lodash-es";

function serializeParams(object, prefix = "") {
  let params = new URLSearchParams();

  if (!isObject(object)) {
    params.append(prefix, object);
  } else {
    Object.entries(object).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          const nestedArrayParams = serializeParams(val, `${prefix}${key}[]`);

          // eslint-disable-next-line no-restricted-syntax
          for (const [nestedKey, nestedValue] of nestedArrayParams.entries()) {
            params.append(nestedKey, nestedValue);
          }
        });
      } else if (isObject(value)) {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          if (isObject(nestedValue)) {
            params = new URLSearchParams({
              ...Object.fromEntries(params),
              ...Object.fromEntries(
                serializeParams(nestedValue, `${prefix}${key}[${nestedKey}]`)
              ),
            });
          } else {
            params.append(`${prefix}${key}[${nestedKey}]`, nestedValue);
          }
        });
      } else if (prefix.length) {
        params.append(`${prefix}[${key}]`, value);
      } else {
        params.append(key, value);
      }
    });
  }

  return params;
}

export default function serializeParamsForRequest(params) {
  return decodeURI(serializeParams(params).toString());
}
