import { Platform } from "quasar";

import { track } from "shared/boot/analytics";
import { v1BaseUrl } from "shared/constants";
import StorageService from "shared/services/StorageService";

function getAuthToken() {
  return StorageService.get("token");
}

function openSystemBrowser(url) {
  if (Platform.is.capacitor) {
    window.location = url;

    return;
  }

  window.open(url, "_system");
}

function openExternalUrl(url, redirect) {
  const decodedUrl = decodeURIComponent(url);

  if (redirect && !Platform.is.capacitor) {
    window.location.href = decodedUrl;

    return;
  }

  window.open(decodedUrl, "_blank");
}

function openPdf(url) {
  if (Platform.is.android) {
    openSystemBrowser(url);
  } else {
    openExternalUrl(url);
  }
}

function openLink(link, options) {
  return Platform.is.mobile
    ? openSystemBrowser(link)
    : openExternalUrl(link, options);
}

function formatUrlParams(params) {
  return Object.entries(params)
    .map((data) => (data[1] ? `${data[0]}=${encodeURIComponent(data[1])}` : ""))
    .join("&");
}

function openProgramTranscript(programAiringId, params) {
  track("Transcript Downloaded", { type: "program" });

  getAuthToken().then((token) => {
    openExternalUrl(
      `${v1BaseUrl}/tv_program_airings/${programAiringId}/transcript.pdf?auth_token=${token}&${formatUrlParams(
        params
      )}`
    );
  });
}

function openChannelTranscript(channelId, params) {
  track("Transcript Downloaded", { type: "channel" });

  getAuthToken().then((token) => {
    openExternalUrl(
      `${v1BaseUrl}/channels/${channelId}/transcript.pdf?auth_token=${token}&${formatUrlParams(
        params
      )}`
    );
  });
}

function openExternalUrlForMention(mention, router, redirect) {
  if (
    Platform.is.android &&
    ((mention.url && /\.pdf$/i.test(mention.url)) ||
      mention.medium === "Social")
  ) {
    openSystemBrowser(mention.authenticated_url || mention.public_link);
  } else if (router && /\.streem\.(com\.au|media)/.test(mention.url)) {
    const pathname = mention.url.match(/(?:^|\w+:\/\/.+?)(\/.+)/)[1];
    router.push(pathname);
  } else {
    openExternalUrl(
      mention.authenticated_url || mention.public_link || mention.url,
      redirect
    );
  }
}

export {
  openExternalUrl,
  openExternalUrlForMention,
  openPdf,
  openLink,
  openSystemBrowser,
  openProgramTranscript,
  openChannelTranscript,
};
