import { mediaId } from "shared/helpers/mentions";
import TranslationService from "shared/services/api/TranslationService";

export default {
  async fetchMentionTranslation(mention) {
    const type = mediaId(mention).substring(0, 2);

    const { data } = await TranslationService.index({
      params: {
        items: [
          {
            id: mention.id,
            type,
            keywords: Object.keys(mention.keywords),
          },
        ],
      },
    });

    const [translation] = data;

    const index = this.translations.findIndex(
      (item) => item.id === translation.id
    );

    if (index !== -1) {
      this.translations.splice(index, 1, translation);
    } else {
      this.translations.push(translation);
    }
  },
  resetTranslations() {
    this.translations = [];
  },
};
