<template>
  <div class="progress-bar">
    <div
      class="progress-bar__fill"
      :class="{
        'progress-bar__fill--red': progress > HIGH_REACT_SCORE_THRESHOLD,
      }"
      :style="progressStyle"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { HIGH_REACT_SCORE_THRESHOLD } from "shared/constants";

const props = defineProps<{ progress: number }>();

const progressStyle = computed(() => ({
  width: `${props.progress}%`,
}));
</script>

<style lang="scss" scoped>
.progress-bar {
  background: #eee;
  height: 10px;
  width: 100%;
  border-radius: 20px;

  &__fill {
    background: #3e4860;
    height: 100%;
    border-radius: 20px;

    &--red {
      background: $react-score-warning;
      height: 100%;
      border-radius: 20px;
    }
  }
}
</style>
