<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :stream="stream"
      :action-sheet-component="actionSheetComponent"
      :options="options"
      :no-highlighting="noHighlighting"
      :always-show-keywords="alwaysShowKeywords"
      :show-checkbox="showCheckbox"
      :list-title="mention.title"
      :selected="selected"
      class="pointer"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #header>
        <span class="alternate q-mt-xs">
          <span
            class="bold"
            :class="{ 'title-hover': !playsOnTveyes }"
            @click="handleClick"
          >
            {{ mention.title }}
          </span>
          <span v-if="hasDuration">&nbsp; {{ formattedPodcastDuration }}</span>
        </span>
        <span class="softer q-mt-sm">
          <TimeAgo :date="mention.timestamp" />
          <span>· {{ mention.source.name }}</span>
        </span>
      </template>

      <template #body>
        <div class="row justify-start no-wrap">
          <template v-if="!playsOnTveyes">
            <UniversalPlayerControlWidget
              v-if="podcastEpisodeFragments.length && !$isAdminMode"
              :clip="clip"
              :start-time="mentionStartTime"
              :class="{ 'in-card': !isListView() }"
              class="play-button in-card"
              @play="playInPopout"
            />
            <QSpinner
              v-else
              size="md"
              class="q-ml-xs"
            />
          </template>

          <div class="q-pl-sm">
            <MentionExcerpts
              :mention="mention"
              :no-highlighting="noHighlighting"
              :always-show-keywords="playsOnTveyes"
              expandable
            />

            <PodcastMentionCardFragmentsDropdown
              v-if="fragmentsMatchingKeywords.length > 1"
              :mention="mention"
              :fragments="fragmentsMatchingKeywords"
              @fragment-clicked="selectedFragment = $event"
            />
          </div>
        </div>

        <div @click.stop>
          <PortalTarget :name="popoutName" />
        </div>
      </template>

      <!-- Display audio player for mention search page in admin mode -->
      <template
        v-if="$isAdminMode"
        #afterRow
      >
        <div v-if="mention.type === 'podcast_episode'">
          <div>
            <!-- Display audio player for audio mentions
            (show "Access Full Media" button before player) -->
            <InlinePlayer
              :clip="mention"
              :mention="mention"
              :poster="thumbnail"
              type="audio"
              auto-fetch-neighbours
              hide-video
            />
          </div>
        </div>
      </template>

      <template #afterBody>
        <div class="soft row q-col-gutter-md">
          <SentimentWidget
            class="q-mt-md"
            :mention="mention"
            :stream="stream"
          />
          <ReactScoreWidget
            class="q-mt-md"
            :mention="mention"
            :is-parent-visible="isIntersecting"
          />
        </div>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="podcastEpisodeFragments.length"
          :clip="clip"
          :start-time="mentionStartTime"
          class="play-button"
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";
import { computed } from "vue";

import { MentionCard, props } from "shared/components/MentionCard";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import InlinePlayer from "shared/components/players/InlinePlayer.vue";
import UniversalPlayerControlWidget from "shared/components/players/UniversalPlayerControlWidget.vue";
import PodcastMentionCardFragmentsDropdown from "shared/components/PodcastMentionCardFragmentsDropdown.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import usePodcastPlayer from "shared/composables/usePodcastPlayer";
import { toSeconds } from "shared/helpers/date";
import { openExternalUrlForMention } from "shared/helpers/external";
import generateId from "shared/helpers/generateId";
import { mentionKeywords } from "shared/helpers/mentions";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PodcastMentionCard",
  components: {
    PortalTarget,
    MentionCard,
    MentionExcerpts,
    InlinePlayer,
    UniversalPlayerControlWidget,
    PodcastMentionCardFragmentsDropdown,
    SentimentWidget,
    ReactScoreWidget,
  },
  provide() {
    return {
      podcastEpisodeDuration: computed(() => this.podcastDuration),
    };
  },
  props,
  emits: ["mention-selected", "mention-removed", "click"],
  setup(mentionProps, context) {
    const universalPlayerStore = useUniversalPlayerStore();
    const { playerOpen, playerIsDismissed } = storeToRefs(universalPlayerStore);
    const { playerShow } = universalPlayerStore;

    const { mentionClicked, playsOnTveyes } = useMention(mentionProps, context);

    const popoutName = `podcast-card-${generateId()}`;

    const {
      podcastStartTimestamp,
      podcastDuration,
      formattedPodcastDuration,
      hasDuration,
      podcastEpisodeFragments,
      clip,
      loadClipInformation,
      isPopoutPlayerPlayingThisClip,
      setPlayerTarget,
      launchPlayer,
    } = usePodcastPlayer(mentionProps, context, popoutName);

    return {
      playerShow,

      playerOpen,
      playerIsDismissed,

      playsOnTveyes,
      mentionClicked,

      popoutName,
      podcastStartTimestamp,
      podcastDuration,
      formattedPodcastDuration,
      hasDuration,
      podcastEpisodeFragments,
      clip,
      loadClipInformation,
      isPopoutPlayerPlayingThisClip,
      setPlayerTarget,
      launchPlayer,
    };
  },
  data() {
    return {
      selectedFragment: null,
      isIntersecting: false,
    };
  },
  computed: {
    fragmentsMatchingKeywords() {
      const keywordsMentioned = mentionKeywords(this.mention);

      const fragmentMatchRegex = new RegExp(
        `\\b(${keywordsMentioned.join("|")})\\b`
      );

      const fragmentObjects = [];

      this.podcastEpisodeFragments.forEach((fragment) => {
        const match = fragmentMatchRegex.exec(fragment.text);

        if (match) {
          fragmentObjects.push({
            ...fragment,
            keyword: match[0],
          });
        }
      });

      return fragmentObjects;
    },
    mentionStartTime() {
      const fragment = this.selectedFragment;

      return fragment
        ? this.podcastStartTimestamp + toSeconds(fragment.startTimeMs)
        : this.podcastStartTimestamp;
    },
  },
  methods: {
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    async handleIntersection({ isIntersecting }) {
      this.isIntersecting = isIntersecting;

      if (isIntersecting) {
        this.loadClipInformation();
      }

      if (this.playerClosed()) return;
      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget(this.playInCard());
    },
    playInPopout() {
      this.launchPlayer(this.playInCard());
    },
    playInCard() {
      return (
        (this.isIntersecting &&
          !this.isListView() &&
          !this.alwaysPlayInPopout) ||
        this.$isMobile
      );
    },
    isListView() {
      return this.options?.list;
    },
    playerClosed() {
      return !this.isPopoutPlayerPlayingThisClip() || this.playerIsDismissed;
    },
    handleClick() {
      if (this.playsOnTveyes) {
        return;
      }

      openExternalUrlForMention(this.mention);
    },
  },
};
</script>
