<template>
  <div>
    <SkeletonMentionCard class="q-mb-md" />
    <SkeletonMentionCard class="q-mb-md" />
    <SkeletonMentionCard />
  </div>
</template>

<script>
import SkeletonMentionCard from "shared/components/SkeletonMentionCard.vue";

export default {
  name: "SkeletonMentionStream",
  components: {
    SkeletonMentionCard,
  },
};
</script>
