<template>
  <div class="c-base-time-picker-list-container">
    <div
      ref="list"
      class="c-base-time-picker-list"
    >
      <BaseButtonList
        :model-value="modelValue"
        :options="options"
        @update:model-value="$emit('update:modelValue', $event)"
      />
    </div>
  </div>
</template>

<script>
const BUTTON_HEIGHT = 32;
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

import BaseButtonList from "./BaseButtonList.vue";

export default {
  name: "InputTimePickerList",
  components: {
    BaseButtonList,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const list = ref(null);
    let scrollTimeout = null;

    const valueOption = computed(() =>
      props.options.find((option) => option === props.modelValue)
    );

    function scrollToOption() {
      if (scrollTimeout) {
        return;
      }

      if (valueOption.value) {
        list.value.scrollTop =
          (valueOption.value.value / props.step) * BUTTON_HEIGHT;
      }
    }

    function onScroll(event) {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      const valueIndex = Math.round(event.target.scrollTop / BUTTON_HEIGHT);
      const newValueOption = props.options[valueIndex];

      scrollTimeout = setTimeout(() => {
        scrollTimeout = null;
        scrollToOption();
      }, 300);

      if (!newValueOption || newValueOption.disabled) return;

      if (valueOption.value?.value === newValueOption.value) return;

      context.emit("update:modelValue", newValueOption);
    }

    watch(
      () => props.modelValue,
      () => {
        scrollToOption();
      }
    );

    onBeforeUnmount(() => {
      clearTimeout(scrollTimeout);
      list.value.removeEventListener("scroll", onScroll);
    });

    onMounted(() => {
      scrollToOption();
      list.value.addEventListener("scroll", onScroll);
    });

    return {
      list,
    };
  },
};
</script>

<style lang="scss" scoped>
.c-base-time-picker-list {
  overflow: auto;
  padding-top: calc(var(--s-size-controls-md) * 2);
  padding-bottom: calc(var(--s-size-controls-md) * 2);
  scrollbar-color: var(--s-color-denim-3) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0;
  }

  :deep() > * > * {
    .base-button {
      width: 64px;
      justify-content: center;
    }
  }

  &-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &::before,
    &::after {
      position: absolute;
      content: "";
      z-index: 10;
      display: block;
      top: calc(var(--s-size-controls-md) * 2);
      left: 0;
      width: 64px;
      height: 1px;
      background: var(--s-color-denim-3);
    }

    &::after {
      top: calc(var(--s-size-controls-md) * 3);
    }
  }
}
</style>
