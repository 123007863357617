<template>
  <div
    ref="root"
    :class="[newDesign && 'new-design']"
    class="input-container"
  >
    <slot name="before" />
    <slot name="label">
      <label
        v-if="label"
        class="block"
        :class="{
          'field-heading': newDesign,
          'q-mb-sm': !description,
          'q-mb-xs': description,
        }"
      >
        {{ label }}
        <slot name="appendLabel" />
      </label>

      <div
        v-if="description"
        class="field-subtext q-mb-sm"
      >
        {{ description }}
      </div>
    </slot>
    <div
      class="input-wrapper"
      :class="{
        prepend: hasPrependSlot,
        append: hasAppendSlot || canClear,
        rounded: rounded,
        'not-filled': notFilled,
        dense: dense,
        borderless: borderless,
      }"
    >
      <slot name="prepend" />
      <div class="relative full-width">
        <textarea
          v-if="type === 'textarea'"
          ref="textbox"
          :value="input"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="inputClasses"
          :style="computedStyle"
          v-bind="eventListeners"
        />
        <input
          v-else
          ref="textbox"
          data-cy="input-text"
          :value="input"
          :placeholder="placeholder"
          :disabled="disabled"
          v-bind="eventListeners"
          :type="inputType"
          :class="inputClasses"
          :style="computedStyle"
          :inputmode="inputMode"
        />

        <slot
          name="textboxAppend"
          :focused="focused"
        />
      </div>
      <div
        v-if="clearable"
        class="clear"
      >
        <QIcon
          v-if="canClear"
          size="16px"
          name="ion-close-circle"
          class="cursor-pointer clear-icon"
          flat
          @click.stop="clear"
        />
      </div>
      <slot name="append" />
    </div>
    <slot name="after" />

    <slot
      name="errors"
      :dirty="dirty"
      :errors="errors"
    >
      <div
        v-if="dirty && errors.length"
        class="field-error q-mt-sm"
      >
        {{ errors[0] }}
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import {
  computed,
  type CSSProperties,
  type InputTypeHTMLAttribute,
  nextTick,
  onMounted,
  ref,
  useAttrs,
  useSlots,
  watch,
} from "vue";

type TextareaInputType = "textarea";

type StringInputType = Extract<
  InputTypeHTMLAttribute,
  "text" | "number" | "password"
>;

type FileInputType = Extract<InputTypeHTMLAttribute, "file">;

export type InputType = StringInputType | FileInputType | TextareaInputType;
</script>

<script lang="ts" setup generic="T extends InputType = 'text'">
import { debounce as lodashDebounce, omit } from "lodash-es";

import useValidation, {
  type Validations,
} from "shared/composables/useValidation";
import { inputNumberOnly } from "shared/helpers/number";

export interface InputTextProps<U = InputType> {
  type?: U | InputType;
  validations?: Validations;
  label?: string;
  description?: string;
  placeholder?: string;
  debounce?: number | string;
  notFilled?: boolean;
  dense?: boolean;
  height?: number | string;
  resize?: CSSProperties["resize"];
  autoFocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  optional?: boolean;
  newDesign?: boolean;
  inputClasses?: string | string[] | Record<string, boolean>;
  autoGrow?: boolean;
  borderless?: boolean;
  numbersOnly?: boolean;
  rounded?: boolean;
}

type InputMode = "numeric" | undefined;

type ModelType = T extends FileInputType
  ? FileList | null
  : T extends StringInputType | TextareaInputType
    ? string | null
    : never;

const props = withDefaults(defineProps<InputTextProps<T>>(), {
  type: "text",
  validations: () => [],
  label: "",
  description: "",
  placeholder: "",
  debounce: 0,
  resize: "none",
  inputClasses: "",
  height: "",
});

const model = defineModel<ModelType>({
  default: undefined,
});

const emit = defineEmits<{
  focus: [];
  blur: [];
  clear: [];
  submit: [];
}>();

const attrs = useAttrs();
const slots = useSlots();

const { input, dirty, errors, isValid, validate, reset } = useValidation({
  modelValue: model,
  validations: props.validations,
  optional: props.optional,
});

const focused = ref(false);
const textbox = ref<HTMLInputElement | HTMLTextAreaElement>();
const root = ref<HTMLDivElement>();

const inputType = computed<InputTypeHTMLAttribute>(() =>
  props.type === "textarea" ? "text" : props.type
);

function focus() {
  focused.value = true;
  textbox.value?.focus();
}

function blur() {
  focused.value = false;
  textbox.value?.blur();
}

function clear() {
  input.value = "";
  emit("clear");
}

function submit() {
  emit("submit");
}

const computedStyle = computed<CSSProperties>(() => {
  const items: CSSProperties = {};

  if (props.height) {
    items.height = `${props.height}px`;
  }

  if (props.resize && props.type === "textarea") {
    items.resize = props.resize;
  }

  return items;
});

const handleInput = computed(() => {
  const emitValue = (event: Event) => {
    const target = event.target as HTMLInputElement;

    if (props.type === "file") {
      (model.value as FileList | null) = target.files;
    } else {
      (model.value as string) = target.value;
    }
  };

  if (props.debounce) {
    return lodashDebounce(emitValue, Number(props.debounce));
  }

  return emitValue;
});

const inputMode = computed<InputMode>(() => {
  let result: InputMode;

  if (props.numbersOnly) {
    result = "numeric";
  }

  return result;
});

const eventListeners = computed(() => ({
  onFocus() {
    focused.value = true;
    emit("focus");
  },
  onBlur() {
    focused.value = false;
    emit("blur");
  },
  onKeypress(event: KeyboardEvent) {
    if (props.numbersOnly) {
      inputNumberOnly(event);
    }
  },
  onKeydown(event: KeyboardEvent) {
    if (props.autoGrow && props.type === "textarea") {
      const textArea = event.target as HTMLTextAreaElement;
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }

    if (["Tab", "Enter"].includes(event.key)) submit();

    if (event.key === "Escape") {
      blur();
      emit("blur");
    }
  },
  ...omit(attrs, ["class", "style"]),
  onInput: handleInput.value,
}));

const hasPrependSlot = computed(() => Boolean(slots.prepend));

const hasAppendSlot = computed(() => Boolean(slots.append));

const canClear = computed(() => props.clearable && input.value);

watch(
  model,
  () => {
    if (props.autoGrow && props.type === "textarea") {
      nextTick(() => {
        if (!textbox.value) return;

        textbox.value.style.height = "auto";

        if (textbox.value.scrollHeight) {
          textbox.value.style.height = `${textbox.value.scrollHeight}px`;
        }
      });
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (props.autoFocus) {
    focus();
  }
});

defineExpose({
  isValid,
  validate,
  reset,
  focus,
  blur,
  el: root,
  errors,
  dirty,
});
</script>

<style lang="scss" scoped>
.input-container:not(.new-design) {
  > .input-wrapper {
    display: flex;
    align-items: center;
    background: $silver-white;
    padding: 0;

    &.not-filled {
      background: transparent;
    }

    &.prepend {
      padding-left: 16px;
    }

    &.rounded {
      border-radius: 6px;
    }

    &.dense {
      &.prepend {
        padding-left: 4px;
      }

      &.append {
        padding-right: 4px;
      }
    }

    > .relative {
      label,
      input {
        font-size: 14px;
        line-height: 18px;
      }

      input {
        width: 100%;
        height: $text-input-height;
        color: $ds-denim-9;
      }

      input[type="number"],
      input[type="number"]::-webkit-inner-spin-button {
        appearance: none;
        appearance: textfield;
        border: none;
      }

      textarea {
        width: 100%;
      }
    }
  }
}
</style>
