import { type AxiosResponse } from "axios";

import type { SentimentRating } from "shared/types";

import ApiClient from "./ApiClient";

class SentimentRatingService extends ApiClient {
  constructor() {
    super("/sentiment_ratings");
  }

  save(
    sentimentRating: SentimentRating,
    { handleErrors = true } = {} as { handleErrors: boolean }
  ): Promise<AxiosResponse<SentimentRating>> {
    return this.create<SentimentRating>({
      params: sentimentRating,
      handleErrors,
    });
  }
}

export default new SentimentRatingService();
