<template>
  <div class="scoped column col no-wrap full-height full-width">
    <JournalistMentionCard
      :journalist="journalist"
      class="journo-card"
    />

    <div class="scroll-y">
      <MentionStream
        :default-range="defaultRange"
        :stream="stream"
        :fetching-service="fetchingService"
        scroll-class="q-pr-sm"
        class="no-header full-width q-mt-sm row full-height flex-1"
        :class="{
          'q-pa-sm': $isMobile,
          'full-height': $q.platform.is.ios,
        }"
        hide-button-bar
        hide-edit-button
        hide-filters-selector
        hide-generate-report-button
        hide-notification-watcher
        hide-stream-visualisation-button
        hide-range-selector
        paginate
        no-highlighting
      >
        <template #noMentions>
          <!-- Hiding for now as we don't have mentions linked to broadcast journalists -->
          <div
            class="hidden no-media-container column justify-center items-center"
          >
            <strong class="q-mb-md">
              {{ $t("journalist_preview.no_mentions_found") }}
            </strong>
          </div>
        </template>
      </MentionStream>
    </div>

    <div
      class="link pointer text-center q-pa-smd feedback"
      @click.stop="emailMediaResearchers"
    >
      {{ $t("journalist_preview.improve_this_information") }}
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import JournalistMentionCard from "shared/components/JournalistMentionCard.vue";
import DateRange from "shared/helpers/DateRange";
import fetchingService from "shared/services/fetching/streamSearch";

export default {
  name: "JournalistPreview",
  components: {
    JournalistMentionCard,
    MentionStream: defineAsyncComponent({
      loader: () => import("shared/components/MentionStream.vue"),
    }),
  },
  props: {
    journalist: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingService,
      defaultRange: DateRange.lastThirtyDays(),
    };
  },
  computed: {
    author() {
      return this.journalist.name;
    },
    stream() {
      let query = "";

      if (this.journalist?.id) {
        query += `journalist_id: ${this.journalist.id}`;
      } else {
        query += `author: "${this.author}"`;
      }

      query += ` AND "${this.author}"`;

      const sourceGroups = this.journalist.publisher_groups;

      if (sourceGroups.length) {
        query += ` AND (source.group: ("${sourceGroups.join(
          '" OR "'
        )}") OR medium: Social)`;
      }

      return {
        media: [],
        online_content: true,
        print_content: true,
        enabled_media: [],
        filters: [],
        disableLocations: true,
        hideFilterCheckboxes: true,
        keywords: query,
      };
    },
  },
  methods: {
    emailMediaResearchers() {
      window.open("mailto:media-researchers@streem.com.au", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}

.journo-card :deep(.card) {
  box-shadow: none;
  background: $body-background;

  .mention-content {
    padding-bottom: 0;
  }
}
</style>
