<template>
  <a
    v-if="mention.advertising_value"
    class="hover-active ave"
    @click.stop.prevent
  >
    <div class="icon">
      {{ currencySymbol }}
    </div>
    <span>{{ compactAve }}</span>
    <StreemTooltip>
      <div class="q-pa-sm">
        <MetricEditorInfoAlert
          show-ave-multiplier-info
          class="q-mb-sm"
        />
        <div>
          {{
            $t("advertising_value_widget.advertising_value_equivalency", {
              ave,
            })
          }}
        </div>
        <div class="softer smaller q-mt-sm">
          {{ $t("ave_disclaimer", { platformName: appConfig.platformName }) }}
        </div>
      </div>
    </StreemTooltip>
  </a>
</template>

<script>
import { storeToRefs } from "pinia";

import MetricEditorInfoAlert from "shared/components/user/MetricEditorInfoAlert.vue";
import useCurrency from "shared/composables/useCurrency";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "AdvertisingValueWidget",
  components: {
    MetricEditorInfoAlert,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const globalStore = useGlobalStore();

    const { appConfig } = storeToRefs(globalStore);

    const { numberToCurrentUserCurrency, currentUserCurrency } = useCurrency();

    return { appConfig, numberToCurrentUserCurrency, currentUserCurrency };
  },
  computed: {
    currencySymbol() {
      return (
        this.currentUserCurrency?.symbolNative || this.$t("currency_symbol")
      );
    },
    compactAve() {
      return this.$n(this.mention.advertising_value, "suffix");
    },
    ave() {
      return this.numberToCurrentUserCurrency(this.mention.advertising_value, {
        currencyDisplay: "code",
        codeOnFront: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ave {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;

  .icon {
    width: 19px;
    height: 19px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $soft;
    color: white;
    font-size: 9px;
    border-radius: 999px;
    line-height: 1;
    font-weight: bold;
  }

  &:hover {
    .icon {
      background-color: #ff7875;
    }
  }
}
</style>
