<template>
  <MentionCard
    :title="mention.title"
    :list-title="listTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :options="options"
    :show-checkbox="showCheckbox"
    :selected="selected"
    class="pointer"
    @click="$emit('click', $event)"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #before>
      <div
        v-if="mention.thumbnails && mention.thumbnails.length"
        class="row items-start"
      >
        <img
          :src="mention.thumbnails[0].url"
          class="thumbnail"
          :alt="$t('tv_segment_mention_card.tv_thumbnails')"
        />
        <img
          v-if="mention.thumbnails.length >= 2"
          :src="mention.thumbnails[1].url"
          class="thumbnail"
          :alt="$t('tv_segment_mention_card.tv_thumbnails')"
        />
      </div>
    </template>

    <template #header>
      <span>
        <strong class="bigger2">
          {{ mention.source.name }}
        </strong>
        {{ mention.source.location }}
      </span>
      <span>
        <TimeAgo
          :date="mention.timestamp"
          class="soft"
        />
        <span v-if="mention.program_airing">
          · {{ mention.program_airing.name }}
        </span>
      </span>
    </template>

    <slot />
  </MentionCard>
</template>

<script>
import { MentionCard, propsExcept } from "shared/components/MentionCard";

export default {
  name: "TVSegmentMentionCard",
  components: {
    MentionCard,
  },
  props: {
    ...propsExcept(["listTitle"]),
  },
  emits: ["click", "mention-selected"],
  computed: {
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
  },
};
</script>
