import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import {
  applyThemeFromRoute,
  routingComponentErrorHandler,
} from "shared/helpers/routing";
import { useGlobalStore } from "shared/stores/global";

import routes from "./routes";

export default function router() {
  const historyFunction =
    process.env.VUE_ROUTER_MODE === "history"
      ? createWebHistory
      : createWebHashHistory;

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : historyFunction;

  const Router = createRouter({
    scrollBehavior: (_to, _from, savedPosition) => savedPosition,
    routes,

    // Leave this as is and make changes in quasar.config.js instead!
    // quasar.config.js -> build -> vueRouterMode
    // quasar.config.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(routingComponentErrorHandler);
  Router.beforeEach(applyThemeFromRoute);

  Router.beforeEach((to, from, next) => {
    if ("title" in to.meta) {
      const title =
        typeof to.meta.title === "function" ? to.meta.title(to) : to.meta.title;

      useGlobalStore().setNavbarTitle(title);
    }

    if ("leftIcon" in to.meta) {
      useGlobalStore().setNavbarLeftIcon(to.meta.leftIcon);
    }

    next();
  });

  Router.afterEach((to) => {
    if (to.meta && to.meta.hideIntercom) {
      document.body.classList.add("hide-intercom");
    } else {
      document.body.classList.remove("hide-intercom");
    }
  });

  return Router;
}
