<template>
  <div
    class="relative inline-block player-div"
    @click.stop
  >
    &nbsp;
    <audio
      v-if="!firstPlay"
      ref="player"
      :src="url"
      @ended="ended"
    />
    <div class="row items-center no-wrap absolute player-absolute-div">
      <span
        v-if="firstPlay"
        class="smaller pointer q-mr-xs"
        @click="togglePlay"
      >
        {{ $t("preview_player.preview") }}
      </span>
      <QBtn
        :disable="loading"
        flat
        round
        dense
        style="margin-left: -8px"
        :aria-label="$t('preview_player.play')"
        @click="togglePlay"
      >
        <QIcon :name="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'" />
      </QBtn>
      <QSlider
        v-if="!firstPlay"
        v-model="currentTime"
        :min="0"
        :max="clipDuration"
        :step="1"
        class="q-mr-sm"
        @update:model-value="setCurrentTime"
      />
      <div
        v-if="!firstPlay"
        class="text-center smaller"
      >
        <QSpinnerDots
          v-if="loading"
          size="25px"
        />
        <span v-else>
          {{ clock }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
      interval: null,
      currentTime: 0,
      clipDuration: 1,
      loading: false,
      firstPlay: true,
    };
  },
  computed: {
    formattedClipDuration() {
      return this.formattedClipLength(this.clipDuration);
    },
    formattedCurrentTime() {
      return this.formattedClipLength(this.currentTime);
    },
    clock() {
      return `${this.formattedCurrentTime}/${this.formattedClipDuration}`;
    },
  },
  mounted() {
    this.interval = setInterval(this.updateClocks, 500); // eslint-disable-line @typescript-eslint/no-implied-eval
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    async togglePlay() {
      if (this.firstPlay) {
        this.firstPlay = false;
        this.loading = true;
        this.$track("Played Clip", { type: "radio" });
        this.loading = false;
      }

      this.$nextTick(() => {
        if (this.isPlaying) {
          this.$refs.player.pause();
        } else {
          this.$refs.player.play().then(() => {
            this.clipDuration = Math.floor(this.$refs.player.duration);
          });
        }

        this.isPlaying = !this.isPlaying;
      });
    },
    updateClocks() {
      if (!this.$refs.player) {
        return;
      }

      this.currentTime = Math.floor(this.$refs.player.currentTime);
    },
    setCurrentTime(time) {
      this.$refs.player.currentTime = time;
    },
    ended() {
      this.$refs.player.pause();
      this.$refs.player.currentTime = 0;
      this.isPlaying = false;
      this.$nextTick().then(() => this.updateClocks());
    },
    formattedClipLength(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration - minutes * 60);

      return `${minutes < 10 ? `0${minutes}` : minutes}:${
        seconds < 10 ? `0${seconds}` : seconds
      }`;
    },
  },
};
</script>

<style lang="scss" scoped>
.player-div {
  width: 180px;
}

.player-absolute-div {
  top: -5px;
  left: 0;
}

.q-slider {
  width: 70px;
}

.q-btn {
  width: 30px;
  height: 25px;
}
</style>
