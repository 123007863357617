import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useMobileStore = defineStore("mobile", {
  actions,
  getters,
  state,
});

export default useMobileStore;
