import { h } from "vue";

import ExternalItemMentionCard from "shared/components/ExternalItemMentionCard.vue";
import JournalistMentionCard from "shared/components/JournalistMentionCard.vue";
import MagazineMentionCard from "shared/components/MagazineMentionCard.vue";
import { props } from "shared/components/MentionCard";
import OnlineMentionCard from "shared/components/OnlineMentionCard.vue";
import PodcastMentionCard from "shared/components/PodcastMentionCard.vue";
import PrintMentionCard from "shared/components/PrintMentionCard.vue";
import RadioMentionCard from "shared/components/RadioMentionCard.vue";
import RadioSegmentMentionCard from "shared/components/RadioSegmentMentionCard.vue";
import SocialMentionCard from "shared/components/SocialMentionCard.vue";
import TranscriptRequestMentionCard from "shared/components/TranscriptRequestMentionCard.vue";
import TVMentionCard from "shared/components/TVMentionCard.vue";
import TvSegmentMentionCard from "shared/components/TVSegmentMentionCard.vue";
import { prettyType } from "shared/helpers/string";

function getCardTypeComponent(context) {
  const { mention } = context;
  const { type } = mention;
  const cardComponent = `${prettyType(type)}MentionCard`;

  return {
    JournalistMentionCard,
    MagazineMentionCard,
    OnlineMentionCard,
    PodcastMentionCard,
    PrintMentionCard,
    RadioMentionCard,
    RadioSegmentMentionCard,
    SocialMentionCard,
    TVMentionCard,
    TvSegmentMentionCard,
    TranscriptRequestMentionCard,
    ExternalItemMentionCard,
  }[cardComponent];
}

export default {
  name: "MentionCardFactory",
  props,
  render() {
    const contextWithEvents = { ...this.$props, ...this.$attrs };
    const component = getCardTypeComponent(this.$props);

    if (!component) return null;

    return h(component, contextWithEvents, this.$slots);
  },
};
