import { MediaAndTranscriptRequestQuery, Stream } from "shared/types";

export function isMediaRequestQuery(
  query: Stream | MediaAndTranscriptRequestQuery
): query is MediaAndTranscriptRequestQuery {
  return "isMediaItems" in query;
}

export function isTranscriptRequestQuery(
  query: Stream | MediaAndTranscriptRequestQuery
): query is MediaAndTranscriptRequestQuery {
  return "isTranscriptRequest" in query;
}

export function isStream(
  stream: Stream | MediaAndTranscriptRequestQuery
): stream is Stream {
  return "type" in stream;
}
