<template>
  <span
    v-if="mention.audience"
    class="flex items-baseline no-wrap"
  >
    <QIcon
      name="ion-ios-people"
      class="q-mr-xs"
    />
    {{ $n(mention.audience, "suffix") }}

    <StreemTooltip>
      <div class="q-pa-sm">
        <MetricEditorInfoAlert
          show-audience-multiplier-info
          class="q-mb-sm"
        />

        <div>
          {{
            $t("print_mention_card.potential_audience_reach", {
              audience: $n(mention.audience, "suffix"),
            })
          }}
        </div>
      </div>
    </StreemTooltip>
  </span>
</template>

<script>
import MetricEditorInfoAlert from "shared/components/user/MetricEditorInfoAlert.vue";

export default {
  name: "PotentialAudienceReachWidget",
  components: {
    MetricEditorInfoAlert,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
  },
};
</script>
