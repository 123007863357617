<template>
  <div>
    <span
      class="block q-pa-sm cursor-pointer hover-active"
      outline
      @click="open"
    >
      <span class="bold">{{
        $t("universal_search_actionsheet_select_media_types.media_types")
      }}</span>
      {{ selectedMediaTypesLabel }}
    </span>

    <QDialog
      v-model="show"
      position="bottom"
      square
    >
      <QCard class="full-width">
        <QCardSection class="header row justify-between q-pa-md">
          <QBtn
            :label="$t('universal_search_actionsheet_select_media_types.back')"
            size="12px"
            dense
            flat
            @click="close"
          />
          <span class="title bold block">{{
            $t("universal_search_actionsheet_select_media_types.media_types")
          }}</span>
          <QBtn
            :label="$t('universal_search_actionsheet_select_media_types.done')"
            size="12px"
            dense
            flat
            @click="saveAndClose"
          />
        </QCardSection>

        <div class="q-pa-md">
          <UniversalSearchMediaTypeFilter
            v-model="localStream"
            :excluded-media="excludedMedia"
          />
        </div>
      </QCard>
    </QDialog>
  </div>
</template>

<script>
import UniversalSearchMediaTypeFilter from "shared/components/search/UniversalSearchMediaTypeFilter.vue";
import { getMediaForStream, getSources } from "shared/helpers/media";

export default {
  name: "UniversalSearchActionsheetSelectMediaTypes",
  components: {
    UniversalSearchMediaTypeFilter,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    excludedMedia: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      show: false,
      sources: getSources(),
      localStream: { ...this.modelValue },
    };
  },
  computed: {
    selectedMediaTypes() {
      return getMediaForStream(this.localStream);
    },
    selectedMediaTypesLabel() {
      if (!this.selectedMediaTypes.length) return "";

      const selectedMediaTypesLength = this.selectedMediaTypes.filter(
        (medium) => !this.excludedMedia.includes(medium)
      ).length;

      const selectedSourcesLength = this.sources.filter(
        ({ medium }) => !this.excludedMedia.includes(medium)
      ).length;

      return `(${selectedMediaTypesLength}/${selectedSourcesLength})`;
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.localStream = { ...this.modelValue };
      },
    },
  },
  methods: {
    open() {
      this.localStream = { ...this.modelValue };
      this.show = true;
    },
    close() {
      this.show = false;
    },
    saveAndClose() {
      this.$emit("update:modelValue", this.localStream);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 19px;
}
</style>
