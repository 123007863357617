import { uniq } from "lodash-es";

export default {
  /**
   *
   * @param {stream, mentions, options}
   * @returns void
   */
  updateSyndicationKeys({ stream, mentions = [], options = {} }) {
    // handle article syndication
    const syndicated = mentions
      .filter((mention) => mention.syndications && mention.syndications.length)
      .map((mention) => mention.syndication_key);

    const combinedSyndicationKeys = options.keepAlreadyLoadedMentions
      ? [...this.streamSyndicationKeys[stream.id], ...syndicated]
      : syndicated;

    this.streamSyndicationKeys = {
      ...this.streamSyndicationKeys,
      ...{ [stream.id]: uniq(combinedSyndicationKeys) },
    };
  },
  resetSyndicationKeys({ stream }) {
    this.streamSyndicationKeys = {
      ...this.streamSyndicationKeys,
      ...{ [stream.id]: [] },
    };
  },
};
