<template>
  <div class="row items-center">
    <slot />

    <span class="q-mr-xs">{{ label }}</span>
    <BaseButtonDropdown
      placement="bottom-start"
      new-design
      color="white"
      size="sm"
      chevron
      bordered
      :label="selectedSort.label"
    >
      <div class="dropdown-list">
        <template
          v-for="(possibility, index) in sortPossibilityOptions"
          :key="index"
        >
          <Radio
            :model-value="selectedValue.sortBy"
            :value="possibility.field"
            :label="possibility.label"
            new-design
            class="dropdown-list__radio_button"
            @update:model-value="setSortBy"
          />
        </template>
      </div>
    </BaseButtonDropdown>

    <span class="q-mr-xs q-ml-md">
      {{ $t("sort_order_picker.order_by_label") }}
    </span>
    <BaseButtonDropdown
      placement="bottom-end"
      close-on-click
      new-design
      color="white"
      size="sm"
      bordered
      chevron
      class="q-ml-xs"
      :label="selectedOrder.label"
    >
      <BaseButtonList
        :model-value="selectedOrderOption"
        :options="orderOptions"
        @update:model-value="setOrderBy"
      />
    </BaseButtonDropdown>
  </div>
</template>

<script>
import { getLocaleValue } from "shared/boot/i18n";
import { BaseButtonDropdown, BaseButtonList } from "shared/components/base";
import { getOrderOptions, getSortPossibilities } from "shared/helpers/mentions";

export default {
  name: "SortOrderPicker",
  components: {
    BaseButtonDropdown,
    BaseButtonList,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        sortBy: "timestamp",
        orderBy: "desc",
        missing: "_last",
      }),
    },
    excludeSortOptionKeys: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => getLocaleValue("sort_order_picker.default_sort_by_label"),
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      selectedValue: JSON.parse(JSON.stringify(this.modelValue)),
    };
  },
  computed: {
    orderOptions() {
      return getOrderOptions();
    },
    selectedOrderOption() {
      return this.orderOptions.find(
        (option) => option.field === this.modelValue.orderBy
      );
    },
    selectedSort() {
      return this.sortPossibilityOptions.find(
        ({ field }) => field === this.selectedValue.sortBy
      );
    },
    selectedOrder() {
      return this.orderOptions.find(
        ({ field }) => field === this.selectedValue.orderBy
      );
    },
    sortPossibilityOptions() {
      const possibilities = getSortPossibilities();

      if (!this.excludeSortOptionKeys.length) {
        return possibilities;
      }

      return possibilities.filter(
        ({ field }) => !this.excludeSortOptionKeys.includes(field)
      );
    },
  },
  watch: {
    modelValue() {
      this.selectedValue = this.modelValue;
    },
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.$emit("update:modelValue", this.selectedValue);
        }
      },
    },
  },
  methods: {
    setSortBy(field) {
      if (this.selectedValue.sortBy !== field) {
        this.selectedValue.sortBy = field;
        this.$emit("update:modelValue", this.selectedValue);
        this.$emit("change", this.selectedValue);
      }
    },
    setOrderBy(order) {
      if (this.selectedValue.orderBy !== order.field) {
        this.selectedValue.orderBy = order.field;

        if (order.field === "asc") {
          this.selectedValue.missing = "_first";
        } else {
          this.selectedValue.missing = "_last";
        }

        this.$emit("update:modelValue", this.selectedValue);
        this.$emit("change", this.selectedValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-label {
  border: 1px solid $hover-background;
  background-color: #fff;
}

.dropdown-list {
  padding: 0.285em 0;

  &__radio_button {
    padding: 8px;
    margin: 0;

    &:hover {
      background-color: var(--s-color-denim-1);
    }
  }
}
</style>
