<template>
  <MentionCard
    :title="mention.title"
    :list-title="listTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :stream="stream"
    :show-checkbox="showCheckbox"
    :selected="selected"
    class="scoped"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #header>
      <span>
        <strong class="bigger2">
          {{ mention.source.name }}
        </strong>
        {{ mention.source.location }}
      </span>
      <div class="row items-center">
        <span class="soft">
          <TimeAgo :date="mention.timestamp" />
          <span v-if="mention.program_airing">
            · {{ mention.program_airing.name }}
          </span>
          · <PreviewPlayer :url="mention.media_url" />
        </span>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import PreviewPlayer from "shared/components/players/PreviewPlayer.vue";

export default {
  name: "RadioSegmentMentionCard",
  components: {
    MentionCard,
    PreviewPlayer,
  },
  props: {
    ...propsExcept(["listTitle"]),
  },
  emits: ["mention-selected"],
  computed: {
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.scoped:deep(.card) {
  .hidden-player {
    height: 0;
    transition: height 0.5s;
    overflow: hidden;
  }

  &:hover {
    .hidden-player {
      height: 50px;
    }
  }
}
</style>
