import { ErrorDialog } from "shared/boot/alert";
import { track } from "shared/boot/analytics";
import { getLocaleText } from "shared/boot/i18n";
import ModalService from "shared/modals/ModalService";
import MediaRequestService from "shared/services/api/MediaRequestService";

function openCreateInstantMediaItemModal(mention, stream, type) {
  ModalService.open("CreateInstantDownloadModal", {
    props: {
      mention,
      stream,
      type,
    },
  });

  track("Instant Edit Media Item modal opened", {
    id: mention.id,
    type: mention.type,
    streamId: stream.id,
  });
}

function openDuplicateMediaItemModal(
  duplicateCheckMention,
  origin,
  type,
  isInstantDownload,
  shouldShowInstantEditButton
) {
  ModalService.open("DuplicateMediaItemTranscriptRequestModal", {
    props: {
      mention: duplicateCheckMention,
      origin,
      type,
      isInstantDownload,
      shouldShowInstantEditButton,
    },
  });

  track("Opened Duplicate Media Item Modal", {
    id: duplicateCheckMention.id,
    type,
  });
}

function openRequestMediaItemModal(mention, origin, stream) {
  ModalService.open("RequestMediaItemModal", {
    props: {
      mention,
      origin,
      stream,
    },
  });

  track("Opened Request Media Item Modal", {
    id: mention.id,
    type: mention.type,
    streamId: stream?.id,
  });
}

export default function useMediaItemRequest() {
  async function openMediaItemRequestModalWithDuplicateCheck(data) {
    let mediaItemCount = 0;

    const {
      duplicateCheckMention,
      mention,
      origin,
      type,
      shouldShowInstantEditButton,
      isInstantDownload,
      stream,
    } = data;

    try {
      mediaItemCount = (
        await MediaRequestService.findDuplicates(duplicateCheckMention, {
          seconds: 300,
        })
      ).length;
    } catch (error) {
      ErrorDialog(
        getLocaleText("actionsheet.errors.duplicate_media_item"),
        error
      );
    } finally {
      if (mediaItemCount > 0) {
        openDuplicateMediaItemModal(
          duplicateCheckMention,
          origin,
          type,
          isInstantDownload,
          shouldShowInstantEditButton
        );
      } else if (isInstantDownload) {
        openCreateInstantMediaItemModal(mention, stream, type);
      } else {
        openRequestMediaItemModal(mention, origin, stream);
      }
    }
  }

  return { openMediaItemRequestModalWithDuplicateCheck };
}
