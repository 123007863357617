import { defineStore } from "pinia";

import actions from "./actions";
import state from "./state";

export const useDesktopStore = defineStore("desktop", {
  state,
  actions,
});

export default useDesktopStore;
