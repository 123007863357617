<template>
  <div
    class="card q-pa-smd border-color-silver-white"
    style="border-left: 5px solid"
  >
    <div class="animated-background q-mb-lg">
      <!-- header -->
      <div
        key="div1"
        style="width: 10px; height: 15px; left: 40px; top: 0"
      />
      <div
        key="div2"
        style="width: 100%; height: 5px; left: 40px; top: 15px"
      />
      <div
        key="div3"
        style="width: 10px; height: 15px; left: 40px; top: 20px"
      />
      <div
        key="div4"
        style="width: 100%; height: 15px; left: 135px; top: 20px"
      />
      <div
        key="div5"
        style="width: 100%; height: 15px; left: 230px; top: 0"
      />

      <!-- body -->
      <div
        key="div6"
        style="width: 100%; height: 7px; left: 40px; top: 35px"
      />
      <div
        key="div7"
        style="width: 100%; height: 8px; left: 0; top: 42px"
      />
      <div
        key="div8"
        style="width: 50px; height: 15px; left: 0; top: 50px"
      />
      <div
        key="div9"
        style="width: 100%; height: 6px; left: 0; top: 65px"
      />
      <div
        key="div10"
        style="width: 100%; height: 14px; left: 230px; top: 71px"
      />
      <div
        key="div11"
        style="width: 50px; height: 15px; left: 0; top: 70px"
      />
      <div
        key="div12"
        style="width: 100%; height: 13px; left: 0; top: 85px"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    90deg,
    rgb(160 160 160 / 25%) 8%,
    rgb(160 160 160 / 50%) 18%,
    rgb(160 160 160 / 25%) 33%
  );
  background-size: 800px 104px;
  height: 96px;
  width: 90%;
  position: relative;
  overflow: hidden;

  div {
    background-color: white;
    position: absolute;
  }
}

@keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
