import addDays from "date-fns/addDays";
import differenceInHours from "date-fns/differenceInHours";
import { sum } from "lodash-es";
import { computed, ref } from "vue";

import { $streemApiV1 } from "shared/boot/api";
import { appInstance } from "shared/boot/app";
import { rollbarError } from "shared/boot/rollbar";
import { toMilliseconds, unixDateTime } from "shared/helpers/date";

export default function useSocialAmplify(props) {
  const socialAmplifyData = ref([]);
  const showSocialAmplifyPopup = ref(false);
  const mention = ref(props.mention);

  const hasAmplify = computed(
    () => appInstance.config.globalProperties.$isDesktop
  );

  const totalSocialAmplifyVolume = computed(() => {
    const values = [];

    socialAmplifyData.value.forEach((bucket) => {
      bucket["2"].buckets.forEach((histogramBucket) => {
        values.push(histogramBucket.total_engagement.value);
      });
    });

    return sum(values);
  });

  const range = computed(() => {
    const now = new Date();
    const after = new Date(mention.value.published_at);
    let before = addDays(new Date(mention.value.published_at), 7);

    if (before > now) {
      before = now;
    }

    return {
      after: unixDateTime(after),
      before: unixDateTime(before),
    };
  });

  const interval = computed(() => {
    const difference = differenceInHours(
      new Date(toMilliseconds(range.value.before)),
      new Date(toMilliseconds(range.value.after))
    );

    if (difference < 2) {
      return "15m";
    }

    if (difference < 48) {
      return "1h";
    }

    return "1d";
  });

  async function loadSocialAmplifyData() {
    try {
      const params = {
        after: range.value.after,
        before: range.value.before,
        interval: interval.value,
        time_zone: mention.value.source.time_zone,
      };

      const { data } = await $streemApiV1.get(
        `articles/${mention.value.id}/social_histogram`,
        { params }
      );

      socialAmplifyData.value = data;
    } catch (error) {
      rollbarError(error);
    }
  }

  function toggleSocialAmplify() {
    showSocialAmplifyPopup.value = !showSocialAmplifyPopup.value;
  }

  return {
    socialAmplifyData,
    showSocialAmplifyPopup,
    hasAmplify,
    totalSocialAmplifyVolume,
    range,
    interval,
    loadSocialAmplifyData,
    toggleSocialAmplify,
  };
}
