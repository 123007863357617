<template>
  <i18n-t
    v-if="mention.original_language"
    scope="global"
    keypath="mention_translation_language.translated_from"
    class="q-pt-md"
    tag="div"
  >
    <template #translated>
      <span class="text-weight-bold">
        <img
          src="https://assets.streem.com.au/icons/translated-content.png"
          class="q-icon notranslate"
        />
        {{ $t("mention_translation_language.translated") }}
      </span>
    </template>
    <template #originalLanguage>
      {{ mention.original_language }}
    </template>
  </i18n-t>
</template>

<script>
export default {
  name: "MentionTranslationLanguage",
  props: {
    mention: {
      type: Object,
      required: true,
    },
  },
};
</script>
