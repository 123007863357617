<template>
  <div>
    <div :class="spanClass">
      <QToggle
        v-model="handleSentimentEnabled"
        dense
        :color="toggleColor"
        class="full-width justify-between"
        :label="$t('sentiment_expandable.sentiment')"
        left-label
      />
    </div>
    <slot name="label-extra" />
    <div
      v-if="sentimentEnabled"
      class="q-mt-xs"
    >
      <div class="row reverse items-center space-around">
        <div
          v-for="option in sentimentOptions"
          :key="option.label"
          class="col"
        >
          <QBtn
            :icon="option.icon"
            :style="sentimentStyle(option)"
            flat
            @click="sentimentOptionClicked(option)"
          >
            <StreemTooltip>{{ option.label }}</StreemTooltip>
          </QBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sentimentOptions from "shared/components/core/pickers/SentimentPicker/sentimentOptions";

export default {
  name: "SentimentExpandable",
  props: {
    toggleColor: {
      type: String,
      default: "",
    },
    spanClass: {
      type: String,
      default: "",
    },
    sentimentEnabled: {
      type: Boolean,
    },
    sentiment: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:sentimentEnabled", "update:sentiment"],
  data() {
    return {
      sentimentOptions,
      selectedSentiment: {},
    };
  },
  computed: {
    handleSentimentEnabled: {
      get() {
        return this.sentimentEnabled;
      },
      set(value) {
        this.$emit("update:sentimentEnabled", value);
      },
    },
  },
  watch: {
    sentiment: {
      deep: true,
      immediate: true,
      handler(oldSentiment, newSentiment) {
        if (JSON.stringify(oldSentiment) === JSON.stringify(newSentiment))
          return;
        const sentiment = {};

        this.sentimentOptions.forEach((option) => {
          const [min, max] = option.range;

          this.sentiment.forEach((range) => {
            if (
              range.min === min ||
              range.max === max ||
              (range.min < min && range.max > max)
            ) {
              sentiment[option.label] = true;
            }
          });
        });

        this.selectedSentiment = sentiment;
      },
    },
    selectedSentiment: {
      deep: true,
      handler() {
        const sentiment = [];

        this.sentimentOptions.forEach((option, index) => {
          if (this.selectedSentiment[option.label]) {
            const [min, max] = option.range;
            const previousOption = this.sentimentOptions[index - 1];

            if (
              previousOption &&
              this.selectedSentiment[previousOption.label]
            ) {
              const previousRange = sentiment[sentiment.length - 1];
              previousRange.max = Math.max(max, previousRange.max);
              previousRange.min = Math.min(min, previousRange.min);
            } else {
              sentiment.push({ min, max });
            }
          }
        });

        if (this.sentimentEnabled) {
          this.$emit("update:sentiment", sentiment);
        }
      },
    },
  },
  methods: {
    sentimentStyle(option) {
      return {
        color: option.color,
        opacity: this.selectedSentiment[option.label] ? 1 : 0.25,
      };
    },
    sentimentOptionClicked(option) {
      Object.assign(this.selectedSentiment, {
        [option.label]: !this.selectedSentiment[option.label],
      });
    },
  },
};
</script>
