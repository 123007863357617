<template>
  <div>
    <div
      v-if="$isDesktop && embeddedPreview"
      :class="{ 'grow col full-height': embeddedPreview }"
      class="column"
    >
      <div class="column flex-1 no-wrap mention grow scoped scroll">
        <div>
          <div class="row justify-between items-center header">
            <BaseButton
              square
              new-design
              color="secondary"
              :label="$t('global.back')"
              icon="ion-ios-arrow-back"
              @click="selectedJournalist = null"
            />
          </div>
        </div>
        <div class="column scroll full-height">
          <JournalistPreview :journalist="selectedJournalist" />
        </div>
      </div>
    </div>

    <div
      v-show="!embeddedPreview"
      class="pagination-container"
    >
      <SkeletonMentionStream v-if="loading && !journalists.length" />

      <div
        v-if="!loading || journalists.length"
        class="col-12 q-gutter-sm"
      >
        <div
          v-for="journalist in journalists"
          :key="`${journalist.type}-${journalist.id}`"
        >
          <JournalistMentionCard
            action-sheet-component="ActionSheet"
            :journalist="journalist"
            @click="openJournalist($event)"
          />
        </div>
        <div v-if="!journalists.length">
          <slot name="no-journalists" />
        </div>
      </div>

      <Pagination
        v-if="!loading && totalPages > 1"
        :page="page"
        :total-pages="totalPages"
        @next="loadNextPage"
        @previous="loadPreviousPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch } from "vue";

import { BaseButton } from "shared/components/base";
import JournalistMentionCard from "shared/components/JournalistMentionCard.vue";
import Pagination from "shared/components/selectors/Pagination.vue";
import SkeletonMentionStream from "shared/components/SkeletonMentionStream.vue";
import JournalistPreview from "shared/components/streams/preview/JournalistPreview.vue";
import requestMentions from "shared/composables/requestMentions";
import DateRange from "shared/helpers/DateRange";
import fetchingService from "shared/services/fetching/streamSearch";

export default {
  name: "UniversalSearchJournalistsList",
  components: {
    BaseButton,
    JournalistMentionCard,
    JournalistPreview,
    Pagination,
    SkeletonMentionStream,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    defaultRange: {
      type: DateRange,
      default: () => DateRange.lastThirtyDays(),
    },
    fetchingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["refreshed", "journalist-clicked", "preview-closed"],
  setup(props, context) {
    const options = reactive({
      ...toRefs(props),
      fetchingService,
      paginate: true,
    });

    const {
      loading,
      loadingError,
      page,
      totalPages,
      mentions: journalists,
      loadMentions: loadJournalists,
      loadPreviousPage,
      loadNextPage,
      refresh,
    } = requestMentions(options);

    const selectedJournalist = ref(null);
    const embeddedPreview = ref(false);

    const range = ref(props.defaultRange || DateRange.today());

    async function refreshJournalists(done = () => {}) {
      await loadJournalists({ forceReload: true, clearMentionsCount: true });
      context.emit("refreshed");
      done();
    }

    onMounted(loadJournalists);

    watch(selectedJournalist, () => {
      if (selectedJournalist.value) {
        context.emit("journalist-clicked", selectedJournalist.value);
      } else {
        embeddedPreview.value = false;
        context.emit("preview-closed");
      }
    });

    return {
      loading,
      loadingError,
      page,
      totalPages,
      journalists,
      loadJournalists,
      loadPreviousPage,
      loadNextPage,
      refresh,

      selectedJournalist,
      embeddedPreview,
      range,
      refreshJournalists,
    };
  },
  methods: {
    openJournalist(journalist) {
      if (this.$isMobile) return;

      this.selectedJournalist = journalist;
      this.embeddedPreview = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--s-button-bg-secondary-default);
}
</style>
