<template>
  <div
    class="filtering-header pointer text-white row justify-center items-center q-pa-sm bold no-shadow smaller"
    @click="$emit('click')"
  >
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script>
export default {
  name: "MentionStreamFilteringHeader",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
};
</script>

<style lang="scss" scoped>
.filtering-header {
  padding: 13px 0;
  background-color: var(--s-color-alert);
}
</style>
