<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    fill="none"
  >
    <g clip-path="url(#clip0_195_1652)">
      <path
        d="M8.24531 2.71716L9.67388 12.7172C9.846 13.922 8.91108 15 7.69398 15H3C1.89543 15 1 14.1046 1 13V3C1 1.89543 1.89543 1 3 1H6.26541C7.26071 1 8.10455 1.73186 8.24531 2.71716Z"
        stroke="currentColor"
      />
      <path
        d="M8.18182 4H16C17.1046 4 18 4.89543 18 6V16C18 17.1046 17.1046 18 16 18H8.2146C7.24803 18 6.41977 17.3088 6.24687 16.3578L6 15"
        stroke="currentColor"
      />
      <path
        d="M9.5534 13.7329L6.99994 18.0001"
        stroke="currentColor"
      />
      <path
        d="M3 10.5L4.36844 5.48238C4.44611 5.19758 4.7048 5 5 5C5.2952 5 5.55389 5.19758 5.63156 5.48238L7 10.5"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M4 8H6"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M12.5 10C12.5 11 13.1 13.2 15.5 14"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M14.5 10C14.5 11 13.9 13.2 11.5 14"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M11 9.5H16"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M13.5 8V9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_195_1652">
        <rect
          width="19"
          height="19"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
