<template>
  <div class="c-react-scores-expandable q-gutter-y-sm">
    <BaseButtonGroup
      gap="none"
      class="q-pb-sm full-width"
    >
      <BaseButton
        v-for="button in reactButtons"
        :key="button"
        :label="button"
        color="secondary"
        active-color="primary"
        :active="selectedReactButton === button"
        new-design
        bordered
        class="flex-1 text-no-wrap"
        :class="{ 'dropdown-button': isDropdownView }"
        @click="selectedReactButton = button"
      />
    </BaseButtonGroup>

    <template v-if="reactScoreAverageSelected">
      <RiskRangeSelector
        v-model="handleReactScore"
        class="q-pt-sm"
        :title="$t('react_score_expandable.react_score_average.title')"
        :sub-title-prefix="
          $t('react_score_expandable.react_score_average.sub_title')
        "
        :tooltip-text="$t('react_score_expandable.react_score_average.tooltip')"
      />
    </template>

    <div
      v-else
      :class="{ 'c-react-scores-expandable__scroll': isDropdownView }"
    >
      <div class="c-react-scores-expandable__categories-help-text">
        {{ $t("react_score_expandable.react_score_grouped_into_4") }}
      </div>

      <RiskRangeSelector
        v-model="handleAggregateFakeness"
        :title="$t('react_score_expandable.controversy.title')"
        :sub-title-prefix="$t('react_score_expandable.controversy.sub_title')"
        :tooltip-text="$t('react_score_expandable.controversy.tooltip')"
      />

      <RiskRangeSelector
        v-model="handleAggregateEmotionality"
        :title="$t('react_score_expandable.emotionality.title')"
        :sub-title-prefix="$t('react_score_expandable.emotionality.sub_title')"
        :tooltip-text="$t('react_score_expandable.emotionality.tooltip')"
      />

      <RiskRangeSelector
        v-model="handleAggregateHarmful"
        :title="$t('react_score_expandable.harm.title')"
        :sub-title-prefix="$t('react_score_expandable.harm.sub_title')"
        :tooltip-text="$t('react_score_expandable.harm.tooltip')"
      />

      <RiskRangeSelector
        v-model="handleAggregateSpam"
        :title="$t('react_score_expandable.spam.title')"
        :sub-title-prefix="$t('react_score_expandable.spam.sub_title')"
        :tooltip-text="$t('react_score_expandable.spam.tooltip')"
      />
    </div>
  </div>
</template>

<script>
import { BaseButton, BaseButtonGroup } from "shared/components/base";
import RiskRangeSelector from "shared/components/selectors/RiskRangeSelector.vue";

export default {
  name: "ReactScoreExpandable",
  components: {
    RiskRangeSelector,
    BaseButton,
    BaseButtonGroup,
  },
  props: {
    reactScoreAverageSelected: {
      type: Boolean,
    },
    reactScore: {
      type: Object,
      default: () => ({}),
    },
    aggregateEmotionality: {
      type: Object,
      default: () => ({}),
    },
    aggregateFakeness: {
      type: Object,
      default: () => ({}),
    },
    aggregateSpam: {
      type: Object,
      default: () => ({}),
    },
    aggregateHarmful: {
      type: Object,
      default: () => ({}),
    },
    isDropdownView: {
      type: Boolean,
    },
  },
  emits: [
    "search",
    "update:reactScoreAverageSelected",
    "update:reactScore",
    "update:aggregateEmotionality",
    "update:aggregateFakeness",
    "update:aggregateSpam",
    "update:aggregateHarmful",
  ],
  data() {
    return {
      reactButtons: [
        this.$t("react_score_expandable.react_score_average_button"),
        this.$t("react_score_expandable.react_categories_button"),
      ],
    };
  },
  computed: {
    selectedReactButton: {
      get() {
        return this.reactScoreAverageSelected
          ? this.$t("react_score_expandable.react_score_average_button")
          : this.$t("react_score_expandable.react_categories_button");
      },
      set(button) {
        this.$emit(
          "update:reactScoreAverageSelected",
          button ===
            this.$t("react_score_expandable.react_score_average_button")
        );
      },
    },
    handleReactScore: {
      get() {
        return this.reactScore;
      },
      set(val) {
        this.$emit("update:reactScore", val);
        this.$emit("search");
      },
    },
    handleAggregateEmotionality: {
      get() {
        return this.aggregateEmotionality;
      },
      set(val) {
        this.$emit("update:aggregateEmotionality", val);
        this.$emit("search");
      },
    },
    handleAggregateFakeness: {
      get() {
        return this.aggregateFakeness;
      },
      set(val) {
        this.$emit("update:aggregateFakeness", val);
        this.$emit("search");
      },
    },
    handleAggregateSpam: {
      get() {
        return this.aggregateSpam;
      },
      set(val) {
        this.$emit("update:aggregateSpam", val);
        this.$emit("search");
      },
    },
    handleAggregateHarmful: {
      get() {
        return this.aggregateHarmful;
      },
      set(val) {
        this.$emit("update:aggregateHarmful", val);
        this.$emit("search");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.c-react-scores-expandable {
  padding-top: 15px;

  &__scroll {
    height: 400px;
    overflow: auto;
  }

  &__categories-help-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--s-color-denim-9);
    padding-bottom: 20px;
  }
}
</style>
