export default {
  getNotifications(state) {
    if (state.notifications && state.notifications.length > 0) {
      return state.notifications.slice(-1)[0];
    }

    return false;
  },
  getOrientation(state) {
    if (Math.abs(state.orientationAngle) === 90) return "landscape";

    return "portrait";
  },
  getKeyboardOpenStyle(state) {
    return state.keyboardOpen
      ? { marginBottom: `${state.keyboardHeight}px` }
      : {};
  },
};
