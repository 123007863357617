<template>
  <AlertMessage
    v-if="showMultiplierInfo"
    type="info"
    icon-size="1rem"
    v-bind="$attrs"
  >
    {{
      $t("advertising_value_widget.tooltip_info", {
        multiplier: $t(multiplierField),
        value: multiplierValue,
        lastUpdatedBy: lastUpdatedBy,
      })
    }}
  </AlertMessage>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, type Ref } from "vue";

import { AlertMessage } from "shared/components/base";
import type { OrganisationAggregationMultiplier } from "shared/resources";
import { useUserStore } from "shared/stores/user";
import type { Nullable } from "shared/types";

enum MultiplierField {
  AVE = "metric_editor.ave_title",
  AUDIENCE = "metric_editor.par_title",
}

const props = defineProps<{
  showAveMultiplierInfo?: boolean;
  showAudienceMultiplierInfo?: boolean;
}>();

const userStore = useUserStore();

const { multiplier } = storeToRefs(userStore) as unknown as {
  multiplier: Ref<Nullable<OrganisationAggregationMultiplier>>;
};

const showMultiplierInfo = computed<boolean>(() => {
  if (!multiplier.value?.id) return false;

  return (
    (props.showAveMultiplierInfo && multiplier.value.aveMultiplier !== 1.0) ||
    (props.showAudienceMultiplierInfo &&
      multiplier.value.audienceMultiplier !== 1.0)
  );
});

const multiplierField = computed<MultiplierField>(() =>
  props.showAveMultiplierInfo ? MultiplierField.AVE : MultiplierField.AUDIENCE
);

const multiplierValue = computed<number | undefined>(() =>
  props.showAveMultiplierInfo
    ? multiplier.value?.aveMultiplier
    : multiplier.value?.audienceMultiplier
);

const lastUpdatedBy = computed<string | undefined>(() =>
  props.showAveMultiplierInfo
    ? multiplier.value?.aveMultiplierLastUpdatedBy?.name
    : multiplier.value?.audienceMultiplierLastUpdatedBy?.name
);
</script>
