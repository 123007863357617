<template>
  <div>
    <img
      v-if="imageUrl"
      :src="imageUrl"
      :alt="alt"
      :class="imageClass"
      @error="imageError = true"
      @click="emit('click')"
    />
    <QIcon
      v-else-if="fallbackIcon"
      :name="fallbackIcon"
      :class="imageClass"
      @click="emit('click')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

export interface Props {
  src?: string | null;
  fallbackIcon: string;
  alt?: string;
  imageClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  src: null,
  alt: "",
  imageClass: "",
});

const emit = defineEmits<{
  click: [];
}>();

const imageError = ref(false);

const imageUrl = computed(() => {
  if (imageError.value) return null;

  return props.src;
});

defineExpose({ imageError });
</script>
