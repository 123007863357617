<template>
  <span>
    <p
      class="hover-active user-select-none q-mt-md"
      @click="showMatchingFragments = !showMatchingFragments"
    >
      {{
        showMatchingFragments
          ? $t("podcast_mention_card_fragments_dropdown.hide_fragments", {
              fragmentLength: fragments.length,
            })
          : $t("podcast_mention_card_fragments_dropdown.show_fragments", {
              fragmentLength: fragments.length,
            })
      }}
    </p>

    <div
      v-if="showMatchingFragments"
      class="publishers-list"
    >
      <span
        v-for="fragment in fragments"
        :key="fragment.id"
        class="no-shrink hover-active q-pb-xs"
        @click.stop="fragmentClicked(fragment)"
      >
        <span>
          <QIcon
            name="img:https://assets.streem.com.au/icons/play-media.svg"
            size="14px"
          />
        </span>

        <span class="bold">
          {{ fragment.keyword }}
          <StreemTooltip>
            {{
              $t("podcast_mention_card_fragments_dropdown.play_audio_from", {
                fragmentStartTime: fragmentStartTime(fragment),
              })
            }}
          </StreemTooltip>
        </span>
        <span
          style
          class="muted pull-right syndication-time"
        >
          {{ fragmentStartTime(fragment) }}
        </span>
      </span>
    </div>
  </span>
</template>

<script>
import { secondsToTimecodeWithSeconds, toSeconds } from "shared/helpers/date";

export default {
  name: "PodcastMentionCardFragmentsDropdown",
  props: {
    mention: {
      type: Object,
      required: true,
    },
    fragments: {
      type: Array,
      required: true,
    },
  },
  emits: ["fragment-clicked"],
  data() {
    return {
      showMatchingFragments: false,
    };
  },
  methods: {
    fragmentStartTime({ startTimeMs }) {
      return secondsToTimecodeWithSeconds(toSeconds(startTimeMs), true);
    },
    fragmentClicked(fragment) {
      this.$emit("fragment-clicked", fragment);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-select-none {
  user-select: none;
}
</style>
