<template>
  <div>
    <span
      class="q-pa-sm"
      outline
    >
      <span class="bold">{{ $t("range_picker_action_sheet.date_range") }}</span>
      <QBtn
        flat
        dense
        icon="navigate_before"
        @click="previousDay()"
      />
      <span @click="open">
        {{ formatIntlDate(modelValue.after, { dateStyle: "medium" }) }}
        to
        {{ formatIntlDate(modelValue.before, { dateStyle: "medium" }) }}
      </span>
      <QBtn
        flat
        dense
        icon="navigate_next"
        @click="nextDay()"
      />
    </span>

    <QDialog
      v-model="show"
      position="bottom"
    >
      <QCard class="full-width">
        <QCardSection class="header row justify-between q-pa-md">
          <QBtn
            :label="$t('range_picker_action_sheet.back_label')"
            size="12px"
            dense
            flat
            @click="close"
          />
          <span class="title bold block">{{
            $t("range_picker_action_sheet.date_range")
          }}</span>
          <QBtn
            :label="$t('range_picker_action_sheet.done_label')"
            size="12px"
            dense
            flat
            @click="saveAndClose"
          />
        </QCardSection>

        <ul class="list">
          <li
            v-for="(range, index) in ranges"
            :key="index"
            class="q-pa-md"
            :class="{ selected: range.toString() === selectedRange.toString() }"
            @click="selectRange(range)"
          >
            {{ range.toString() }}
            <span class="block soft">
              {{ formatIntlDate(range.after, { dateStyle: "medium" }) }}
              to
              {{ formatIntlDate(range.before, { dateStyle: "medium" }) }}
            </span>
          </li>
        </ul>
      </QCard>
    </QDialog>
  </div>
</template>

<script>
import {
  addTime,
  formatIntlDate,
  parseDate,
  subtractTime,
  unixDateTime,
} from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";

export default {
  name: "RangePickerActionSheet",
  props: {
    modelValue: {
      type: DateRange,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      show: false,
      selectedRange: this.modelValue,
      ranges: [
        DateRange.today(),
        DateRange.yesterday(),
        DateRange.lastThreeDays(),
        DateRange.lastSevenDays(),
        DateRange.lastThirtyDays(),
        DateRange.lastNinetyDays(),
        DateRange.currentMonth(),
        DateRange.lastMonth(),
      ],
    };
  },
  methods: {
    formatIntlDate,
    open() {
      this.selectedRange = this.modelValue;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    selectRange(range) {
      this.selectedRange = range;
    },
    saveAndClose() {
      this.$emit("update:modelValue", this.selectedRange);
      this.close();
    },
    previousDay() {
      ["before", "after"].forEach((boundary) => {
        this.selectedRange[boundary] = unixDateTime(
          subtractTime(parseDate(this.selectedRange[boundary]), 1, "day")
        );
      });
    },
    nextDay() {
      ["before", "after"].forEach((boundary) => {
        this.selectedRange[boundary] = unixDateTime(
          addTime(parseDate(this.selectedRange[boundary]), 1, "day")
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 19px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid #ddd;

    &.selected {
      background: #444;
      color: white;

      .soft {
        color: rgb(255 255 255 / 70%);
      }
    }

    .soft {
      font-size: 12px;
    }
  }
}
</style>
