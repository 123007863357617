<template>
  <span @click.stop.prevent>
    <BaseButton
      :label="totalSocialEngagementFormatted"
      :disabled="!total"
      icon="fas fa-chart-pie"
      color="menu-social-bar"
      padding="sm"
      class="bold"
      flat
      @click="onClick"
    />
    <StreemTooltip
      anchor="center right"
      self="center left"
      :offset="[4, 4]"
    >
      {{ $t("social_amplify_icon.social_amplify") }}
      <span v-if="!total && !enoughTimeHasPassed">
        {{ $t("social_amplify_icon.not_enough_social_data_recent_article") }}
      </span>
      <span v-else-if="!total">
        {{ $t("social_amplify_icon.not_enough_social_data") }}
      </span>
    </StreemTooltip>
  </span>
</template>

<script>
import differenceInMinutes from "date-fns/differenceInMinutes";

import { BaseButton } from "shared/components/base";
import { suffix } from "shared/helpers/number";

const MINIMUM_MINUTES_TO_ELAPSE = 15;

export default {
  name: "SocialAmplifyIcon",
  components: {
    BaseButton,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  emits: ["click"],
  computed: {
    minutesElapsedSincePublish() {
      const now = new Date();
      const publishedAt = new Date(this.mention.published_at);

      return differenceInMinutes(now, publishedAt);
    },
    enoughTimeHasPassed() {
      return this.minutesElapsedSincePublish >= MINIMUM_MINUTES_TO_ELAPSE;
    },
    totalSocialEngagementFormatted() {
      return suffix(this.total);
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
