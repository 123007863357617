export default function applyThemeConfig(routes) {
  return routes.map((route) => {
    const updatedRoute = {
      ...route,
      meta: {
        product: "default",
        ...route.meta,
      },
    };

    return updatedRoute;
  });
}
