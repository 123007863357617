<template>
  <div>
    <div
      v-for="(sortOption, index) in sortingOptions"
      :key="index"
      class="row justify-between q-my-md"
    >
      <SortOrderPicker
        v-model="sortingOptions[index]"
        :exclude-sort-option-keys="excludedSortingOptions"
        :label="ordinalNumber(index + 1)"
        class="q-mt-sm"
      />
      <QBtn
        v-if="sortingOptions.length > 1"
        icon="delete"
        flat
        dense
        class="q-mt-none"
        @click="removeSortingOption(index)"
      />
    </div>
    <div class="row justify-center">
      <QBtn
        icon="fa fa-plus"
        :title="$t('sorting_expandable.add_sorting')"
        flat
        class="align-center self-center"
        @click="addSortingOptions"
      />
    </div>
  </div>
</template>

<script>
import SortOrderPicker from "shared/components/core/pickers/SortOrderPicker.vue";
import { ordinalNumber } from "shared/helpers/number";

export default {
  name: "SortingExpandable",
  components: {
    SortOrderPicker,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    excludedSortingOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    sortingOptions: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  methods: {
    ordinalNumber,
    addSortingOptions() {
      this.sortingOptions = [
        ...this.sortingOptions,
        { sortBy: "timestamp", orderBy: "desc" },
      ];
    },
    removeSortingOption(index) {
      const options = this.sortingOptions;
      options.splice(index, 1);
      this.sortingOptions = [...options];
    },
  },
};
</script>
