import { storeToRefs } from "pinia";

import {
  CANADA,
  DEFAULT_LOCATION_IDS,
  EUROPE,
  EUROPE_EXCLUDED_LOCATION_IDS,
  NORTH_AMERICA,
  NORTH_AMERICA_LOCATION_IDS,
  PREFERRED_LOCATIONS_BY_REGION,
  SOUTH_AMERICA,
  TOP_LOCATION_NAMES,
  USA,
} from "shared/constants/locations";
import { useGlobalStore } from "shared/stores/global";

export function getDefaultCountryLocations(region) {
  let defaultCountryLocations = [];

  if (NORTH_AMERICA_LOCATION_IDS.includes(region)) {
    defaultCountryLocations = NORTH_AMERICA_LOCATION_IDS;
  } else if (DEFAULT_LOCATION_IDS.includes(region)) {
    defaultCountryLocations = [region];
  } else {
    defaultCountryLocations = PREFERRED_LOCATIONS_BY_REGION[region] || [];
  }

  return defaultCountryLocations;
}

export function shouldIncludeLocation(location, { region, continent }) {
  const defaultCountryLocations = getDefaultCountryLocations(region);

  if (continent === EUROPE.name) {
    const excludedLocation = EUROPE_EXCLUDED_LOCATION_IDS.find(
      (id) => id === region
    );

    if (excludedLocation) {
      return location.country_code === excludedLocation;
    }

    return location.name === EUROPE.name;
  }

  return (
    defaultCountryLocations.includes(location.country_code) ||
    defaultCountryLocations.includes(location.name)
  );
}

export function getTopLocations(locations, userLocation) {
  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  let topLocationNames = [...TOP_LOCATION_NAMES];

  // Workaround for AU environment until we have all required locations in the database
  if (appConfig.value.platformName === "Streem") {
    // Filter out usa and canada
    topLocationNames = topLocationNames.filter(
      (location) => ![USA.name, CANADA.name].includes(location)
    );

    // Insert north america before south america
    const index = topLocationNames.indexOf(SOUTH_AMERICA.name);
    topLocationNames.splice(index, 0, NORTH_AMERICA.name);
  }

  const filteredLocations = locations.filter((location) =>
    topLocationNames.includes(location.name)
  );

  const { region, continent } = userLocation;

  let preferredLocations = [];

  if (region) {
    preferredLocations = PREFERRED_LOCATIONS_BY_REGION[region] || [];
  }

  if (preferredLocations.length === 0 && continent) {
    preferredLocations =
      PREFERRED_LOCATIONS_BY_REGION[continent.toUpperCase()] || [];
  }

  const locationOrder = [
    ...preferredLocations,
    ...topLocationNames.filter(
      (location) => !preferredLocations.includes(location)
    ),
  ];

  return filteredLocations.sort((locationA, locationB) => {
    const locationAIndex = locationOrder.indexOf(locationA.name);
    const locationBIndex = locationOrder.indexOf(locationB.name);

    if (locationAIndex === locationBIndex) {
      return locationA.position - locationB.position;
    }

    return locationAIndex - locationBIndex;
  });
}

export function formattedLocationHierarchy(locationNames) {
  return [...locationNames]
    .filter((location) => location !== "Worldwide")
    .join(" > ");
}
