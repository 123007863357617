<template>
  <span
    :class="[$attrs.class, $attrs.staticClass, 'color-indicator']"
    :style="{
      'background-color': $props.color,
      width: $props.size,
      height: $props.size,
      border: $props.borderless ? 0 : '1px solid #ccc',
      'min-width': $props.size,
    }"
  />
</template>

<script>
export default {
  name: "ColorIndicator",
  props: {
    color: {
      type: String,
      default: "#444",
    },
    size: {
      type: String,
      default: "30px",
    },
    borderless: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.color-indicator {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
</style>
