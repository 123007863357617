import { $streemApiV1 } from "shared/boot/api";

export default {
  get({ stream, mention, options = {} }) {
    return $streemApiV1
      .get(`mentions/${mention.id}/syndications_matching_stream`, {
        params: {
          stream_id: stream.id,
          mention_type: mention.type,
          ...options,
        },
      })
      .then((response) => response.data);
  },
};
