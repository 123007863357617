<template>
  <div class="flex column">
    <template
      v-for="(option, index) in options"
      :key="index"
    >
      <div class="column">
        <BaseButton
          new-design
          menu-item
          active-color="secondary"
          :active="modelValue === option"
          :label="option.label"
          :icon="option.icon"
          :new-icon="option.newIcon"
          :disabled="option.disabled"
          @click="selectOption(option)"
        >
          <slot :option="option" />
        </BaseButton>
        <StreemTooltip v-if="option.enableTooltip">
          {{ option.tooltipText }}
        </StreemTooltip>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
export interface Option {
  label: string;
  value?: string;
  icon?: string;
  newIcon?: boolean;
  disabled?: boolean;
  enableTooltip?: boolean;
  tooltipText?: string;
}

interface Props {
  modelValue?: Option;
  options?: Option[];
}
</script>

<script setup lang="ts">
import BaseButton from "shared/components/base/BaseButton.vue";

withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  options: () => [],
});

const emit = defineEmits(["update:modelValue"]);

function selectOption(option: Option) {
  emit("update:modelValue", option);
}
</script>
