<template>
  <div
    class="base-button-group"
    :class="buttonGroupClasses"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  gap?: "none" | "xs" | "px";
}

const props = withDefaults(defineProps<Props>(), {
  gap: "none",
});

const buttonGroupClasses = computed(
  () => `base-button-group__gap-${props.gap}`
);
</script>
