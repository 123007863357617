<template>
  <BaseTranslationDescription error>
    {{ $t("translation_error.failed_to_translate") }}
    <div
      class="translation-error-action"
      @click="emit('retry')"
    >
      {{ $t("global.try_again") }}
    </div>
  </BaseTranslationDescription>
</template>

<script setup>
import BaseTranslationDescription from "./BaseTranslationDescription.vue";

const emit = defineEmits(["retry"]);
</script>

<style lang="scss" scoped>
.translation-error-action {
  display: inline-block;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
</style>
