import { format, isAfter, isBefore, parse } from "date-fns";
import { computed, ref, toRefs, watch } from "vue";

import {
  fromCurrentToGivenTimezone,
  getTimezone,
  swapTimezones,
} from "shared/helpers/date";

export default function useTime(props, context) {
  const {
    modelValue,
    minTime,
    maxTime,
    timezone,
    minuteStep,
    interval,
    showSeconds,
  } = toRefs(props);

  const error = ref(false);
  const pendingLocalTime = ref(null);
  const previousLocalTime = ref(modelValue.value);
  const hourOptions = ref([]);
  const minuteOptions = ref([]);
  const secondOptions = ref([]);

  const localTime = computed(() => {
    if (timezone.value) {
      return fromCurrentToGivenTimezone(modelValue.value, timezone.value);
    }

    return modelValue.value;
  });

  const newLocalTime = computed(
    () => pendingLocalTime.value || localTime.value
  );

  const localMaxTime = computed(() => {
    if (!maxTime.value) {
      return null;
    }

    if (timezone.value) {
      return fromCurrentToGivenTimezone(maxTime.value, timezone.value);
    }

    return maxTime.value;
  });

  const localMinTime = computed(() => {
    if (!minTime.value) {
      return null;
    }

    if (timezone.value) {
      return fromCurrentToGivenTimezone(minTime.value, timezone.value);
    }

    return minTime.value;
  });

  const formatString = computed(() =>
    showSeconds.value ? "HH:mm:ss" : "HH:mm"
  );

  function setDate(date) {
    let invalid = false;
    let dateError = false;

    if (date.toString() === "Invalid Date") {
      invalid = true;
      dateError = true;
    }

    if (localMinTime.value && isBefore(date, localMinTime.value)) {
      dateError = true;
    }

    if (localMaxTime.value && isAfter(date, localMaxTime.value)) {
      dateError = true;
    }

    if (minuteStep.value > 1) {
      const minutes = Number(format(date, "m"));

      if (minutes % minuteStep.value !== 0) {
        dateError = true;
      }
    }

    error.value = dateError;

    if (!invalid) {
      pendingLocalTime.value = timezone.value
        ? swapTimezones(date, timezone.value, getTimezone())
        : date;
    }

    if (!dateError) {
      context.emit("update:modelValue", pendingLocalTime.value);
    }
  }

  function setHour(newHour) {
    const minutesSeconds = format(newLocalTime.value, "m:s");
    setDate(parse(`${newHour}:${minutesSeconds}`, "H:m:s", newLocalTime.value));
  }

  function setMinute(newMinute) {
    const seconds = format(newLocalTime.value, "s");
    setDate(parse(`${newMinute}:${seconds}`, "m:s", newLocalTime.value));
  }

  function setSecond(newSecond) {
    setDate(parse(newSecond, "s", newLocalTime.value));
  }

  const time = computed({
    get() {
      return format(newLocalTime.value, formatString.value);
    },
    set(newTime) {
      if (newTime.length !== formatString.value.length) {
        return;
      }

      const parsedDate = parse(newTime, formatString.value, localTime.value);

      setDate(parsedDate);
    },
  });

  const localHour = computed(() => Number(format(newLocalTime.value, "H")));
  const localMinute = computed(() => Number(format(newLocalTime.value, "m")));
  const localSecond = computed(() => Number(format(newLocalTime.value, "s")));

  const hourOption = computed({
    get() {
      return hourOptions.value.find(
        (option) => option.value === localHour.value
      );
    },
    set(newOption) {
      setHour(newOption.value);
    },
  });

  const minuteOption = computed({
    get() {
      return minuteOptions.value.find(
        (option) => option.value === localMinute.value
      );
    },
    set(newOption) {
      setMinute(newOption.value);
    },
  });

  const secondOption = computed({
    get() {
      return secondOptions.value.find(
        (option) => option.value === localSecond.value
      );
    },
    set(newOption) {
      setSecond(newOption.value);
    },
  });

  function getOptionTime(index, unit, direction) {
    const hour = unit === "h" ? index : localHour.value;
    let minute = unit === "m" ? index : localMinute.value;
    let second = unit === "s" ? index : localSecond.value;

    if (direction) {
      if (unit === "h") {
        minute = direction === "after" ? 0 : 59;
        second = direction === "after" ? 0 : 59;
      }

      if (unit === "m") {
        second = direction === "after" ? 0 : 59;
      }
    }

    const hourMinuteSecond = `${hour}:${minute}:${second}`;

    return parse(hourMinuteSecond, "H:m:s", newLocalTime.value);
  }

  function isDisabled(index, unit) {
    if (localMinTime.value) {
      const optionTime = getOptionTime(index, unit, "before");

      if (isBefore(optionTime, localMinTime.value)) {
        return true;
      }
    }

    if (localMaxTime.value) {
      const optionTime = getOptionTime(index, unit, "after");

      if (isAfter(optionTime, localMaxTime.value)) {
        return true;
      }
    }

    if (interval.value) {
      let timestamp = getOptionTime(index, unit).getTime();

      if (localMinTime.value) {
        timestamp -= localMinTime.value.getTime();
      } else {
        timestamp -= localTime.value.getTime();
      }

      return timestamp % interval.value !== 0;
    }

    return false;
  }

  function setOptions(unit, intervals, step = 1) {
    const options = [];

    for (let index = 0; index < intervals; index += 1) {
      let label = index;

      if (index < 10) {
        label = `0${label}`;
      }

      let show = true;

      if (step > 1 && index % step !== 0) {
        show = false;
      }

      if (show) {
        const disabled = isDisabled(index, unit);

        options.push({
          value: index,
          label,
          disabled,
        });
      }
    }

    return options;
  }

  watch(modelValue, () => {
    pendingLocalTime.value = null;
  });

  watch(
    newLocalTime,
    () => {
      hourOptions.value = setOptions("h", 24);
      minuteOptions.value = setOptions("m", 60, minuteStep.value);
      secondOptions.value = setOptions("s", 60);
    },
    { immediate: true }
  );

  return {
    pendingLocalTime,
    previousLocalTime,
    error,
    hourOptions,
    hourOption,
    minuteOptions,
    minuteOption,
    secondOptions,
    secondOption,

    localTime,
    newLocalTime,
    localMaxTime,
    localMinTime,
    formatString,
    time,

    setHour,
    setMinute,
    setSecond,
  };
}
