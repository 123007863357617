<template>
  <div class="row card q-pa-md">
    <div
      :class="{
        'col-7': $isDesktop,
        'full-width': $isMobile,
      }"
      class="flex no-wrap"
    >
      <div class="profile-img-container q-mr-md">
        <img
          v-if="profileImageUrl"
          :src="profileImageUrl"
          class="profile-img"
          :alt="$t('journalist_mention_card.journalist_image')"
          @error="imageError = true"
        />
        <QIcon
          v-else
          name="ion-person"
          class="profile-img"
        />
      </div>

      <span>
        <strong
          class="text-h2 hover-active cursor-pointer bold"
          @click="$emit('click', journalist)"
        >
          {{ journalist.name }}
        </strong>

        <span class="block text-paragraph">
          <span v-if="journalistPosition.job_title">
            {{
              $t("journalist_mention_card.position_at", {
                jobTitle: journalistPosition.job_title,
              })
            }}
          </span>

          {{ journalistPosition.source.name }}
        </span>

        <div class="q-mt-sm">
          <span
            v-if="journalistPosition.source.location"
            class="text-help-text soft"
          >
            ({{ journalistPosition.source.location }})
          </span>

          <span class="block text-paragraph">
            {{ journalistPosition.section }}
          </span>
        </div>
      </span>
    </div>

    <div
      class="contact-details"
      :class="{
        'col-5 q-mt-xs': $isDesktop,
        'q-mt-md q-ml-md': $isMobile,
      }"
    >
      <div
        :class="{
          'q-gutter-xs': $isMobile,
          'q-gutter-sm': $isDesktop,
        }"
      >
        <div
          v-for="(phoneNumber, index) in phoneNumbers"
          :key="index"
        >
          <div class="row items-baseline">
            <div class="col-auto">
              <QIcon
                name="ion-md-call"
                class="contact-icon"
                size="14px"
              />
            </div>
            <div
              :class="$isMobile ? 'q-ml-xs' : 'q-ml-sm'"
              class="col text-paragraph"
            >
              {{ phoneNumber.label }}:
              <span
                class="link pointer"
                @click.stop="openDialer(phoneNumber.phone_number)"
              >
                {{ phoneNumber.phone_number }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="journalist.email"
          class="row"
        >
          <div class="row items-baseline">
            <div class="col-auto">
              <QIcon
                name="ion-md-mail"
                class="contact-icon"
                size="14px"
              />
            </div>
            <div
              :class="$isMobile ? 'q-ml-xs' : 'q-ml-sm'"
              class="col row pointer text-paragraph"
            >
              <span
                class="link"
                @click.stop="emailCompose(journalist.email)"
              >
                {{ journalist.email }}
              </span>
              <div
                v-if="hasAlternateEmails"
                class="row"
              >
                <span
                  v-if="!expandedEmails"
                  @click="expandedEmails = true"
                >
                  <strong class="expand-text">
                    {{
                      $t("journalist_mention_card.expand_email_button", {
                        alternateEmailsLength:
                          journalist.alternate_emails.length,
                      })
                    }}
                    <QIcon
                      name="arrow_drop_down"
                      class="contact-icon"
                      size="20px"
                    />
                  </strong>
                </span>
                <span v-else>
                  <strong
                    class="expand-text"
                    @click="expandedEmails = false"
                  >
                    less
                    <QIcon
                      name="arrow_drop_up"
                      class="contact-icon"
                      size="20px"
                    />
                  </strong>
                  <span
                    v-for="email in journalist.alternate_emails"
                    :key="email"
                    class="link row"
                    @click.stop="emailCompose(email)"
                  >
                    {{ email }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="journalist.twitter_handle"
          class="row"
        >
          <div
            class="row items-center"
            @click.stop="twitterCompose"
          >
            <div class="col-auto">
              <QIcon
                name="ion-logo-twitter"
                class="contact-icon"
                size="14px"
              />
            </div>
            <div
              :class="$isMobile ? 'q-ml-xs' : 'q-ml-sm'"
              class="col link pointer text-paragraph"
            >
              {{ journalist.twitter_handle }}
            </div>
          </div>
        </div>

        <JournalistMentionCardOutreachActions
          v-if="hasOutreach"
          :journalist="journalist"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import JournalistMentionCardOutreachActions from "shared/components/JournalistMentionCardOutreachActions.vue";
import { propsExcept } from "shared/components/MentionCard";
import { useUserStore } from "shared/stores/user";

export default {
  name: "JournalistMentionCard",
  components: {
    JournalistMentionCardOutreachActions,
  },
  props: {
    ...propsExcept(["mention", "listTitle"]),
    journalist: {
      type: Object,
      required: true,
    },
  },
  emits: ["click"],
  setup() {
    const userStore = useUserStore();
    const { organisation } = storeToRefs(userStore);

    return { organisation };
  },
  data() {
    return {
      expandedEmails: false,
      imageError: false,
    };
  },
  computed: {
    profileImageUrl() {
      if (this.imageError) return null;

      return this.journalist.avatar_url;
    },
    journalistPosition() {
      if (
        this.journalist.primary_position &&
        this.journalist.primary_position.job_title &&
        this.journalist.primary_position.job_title !== "journalist"
      )
        return this.journalist.primary_position;

      return { section: "", job_title: "", source: { name: "", location: "" } };
    },
    phoneNumbers() {
      if (this.journalist.phone_numbers.length) {
        return this.journalist.phone_numbers;
      }

      if (this.journalist.phone_number) {
        return [
          {
            label: this.$t("journalist_mention_card.work"),
            phone_number: this.journalist.phone_number,
          },
        ];
      }

      return [];
    },
    hasOutreach() {
      return (
        this.$features.has("has_outreach") &&
        this.$permissions.has("can_manage_outreach")
      );
    },
    hasAlternateEmails() {
      return this.journalist.alternate_emails?.length;
    },
  },
  methods: {
    twitterCompose() {
      window.open(
        `https://twitter.com/${this.journalist.twitter_handle.replace(
          "@",
          ""
        )}`,
        "_blank"
      );
    },
    emailCompose(email) {
      window.open(`mailto:${email}`, "_blank");
    },
    openDialer(phoneNumber) {
      window.open(`tel:${phoneNumber}`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-img-container {
  .profile-img {
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $silver-whiter;
    font-size: 65px;
    color: $soft;
  }
}

.contact-icon {
  color: #9b9b9b;
}

.link {
  overflow-wrap: break-word;
  word-break: break-all;
}

.bordered {
  border-left: 1px solid $secondary;
}

.expand-text {
  color: $blue-link-darker;
}

.mobile {
  .profile-img-container {
    .profile-img {
      width: 60px;
      height: 60px;
      font-size: 45px;
    }
  }

  .contact-details {
    padding-left: 60px;
  }
}
</style>
