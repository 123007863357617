<template>
  <div v-if="canShowReactScore">
    <BaseButtonDropdown
      v-if="hasFactmataEnrichment"
      v-model="widgetOpen"
      new-design
      :placement="placement"
      flip
      :fallback-placements="['right-end', 'left-end']"
      :boundary="boundary"
      :offset="popperOffset"
      class="react-score"
      tether
    >
      <template #btn="{ toggle }">
        <div
          class="react-score__button"
          @click.stop.prevent="toggle"
        >
          <div
            ref="buttonIndicator"
            class="react-score__button-indicator"
          >
            <div
              class="react-score__indicator"
              :class="{
                'react-score__indicator--red':
                  averageScore > HIGH_REACT_SCORE_THRESHOLD,
              }"
            >
              <div class="react-score__indicator-mask" />
              <div class="react-score__indicator-end">
                <div />
              </div>
              <div class="react-score__indicator-hole" />
            </div>
          </div>
          <div>{{ averageScore }}</div>

          <StreemTooltip v-if="!hideTooltip">
            {{ $t("react_score_widget.react_score") }}
          </StreemTooltip>
        </div>
      </template>
      <div
        class="react-score__dropdown"
        @click.stop.prevent
      >
        <div class="react-score__header-container">
          <div class="react-score__title">
            <strong>{{ $t("react_score_widget.react_score") }}</strong>
          </div>

          <div
            v-if="averageScore > HIGH_REACT_SCORE_THRESHOLD"
            class="react-score__high-react-label"
          >
            {{ $t("react_score_widget.high_risk") }}
          </div>
        </div>

        <div class="react-score__score">
          {{ averageScore }}
        </div>
        <ReactScoreBar :progress="averageScore" />
        <div>
          <div class="react-score__grid-container">
            <div
              v-for="(stat, statIndex) in stats"
              :key="statIndex"
            >
              <div class="react-score__tiles">
                <div class="column flex">
                  <div class="react-score__label-title">
                    {{ stat.label }}
                  </div>
                  <div class="react-score__value-title">
                    {{ stat.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="react-score__content">
          {{ $t("react_score_widget.react_score_subtext") }}
        </div>
      </div>
    </BaseButtonDropdown>
  </div>
</template>

<script>
import { BaseButtonDropdown } from "shared/components/base";
import ReactScoreBar from "shared/components/ReactScoreBar.vue";
import { HIGH_REACT_SCORE_THRESHOLD } from "shared/constants";

export default {
  name: "ReactScoreWidget",
  components: {
    BaseButtonDropdown,
    ReactScoreBar,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    isParentVisible: {
      type: Boolean,
    },
    placement: {
      type: String,
      default: "right-start",
    },
    boundary: {
      type: HTMLElement,
      default: null,
    },
    hideTooltip: {
      type: Boolean,
    },
  },
  data() {
    return {
      HIGH_REACT_SCORE_THRESHOLD,
      widgetOpen: false,
    };
  },
  computed: {
    canShowReactScore() {
      return this.$features.has("has_react_score");
    },
    hasFactmataEnrichment() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    reactScores() {
      return this.mention.factmata_enrichment || {};
    },
    stats() {
      return [
        {
          label: this.$t("react_score_widget.stats_label.harmful"),
          value: Math.round(this.reactScores.aggregate_harmful * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.controversy"),
          value: Math.round(this.reactScores.aggregate_fakeness * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.spam"),
          value: Math.round(this.reactScores.aggregate_spam * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.emotionality"),
          value: Math.round(this.reactScores.aggregate_emotionality * 100),
        },
      ];
    },
    averageScore() {
      return Math.round(this.reactScores.risk_score * 100);
    },
  },
  watch: {
    widgetOpen(value) {
      if (value) {
        this.$track("Clicked on React Score in Mention Stream", {
          score: this.averageScore,
        });
      }
    },
    isParentVisible(value) {
      if (!value) {
        this.widgetOpen = false;
      }
    },
  },
  mounted() {
    if (this.hasFactmataEnrichment) {
      this.$nextTick(() => {
        this.rotateIndicator(this.$refs.buttonIndicator, this.averageScore);
      });
    }
  },
  methods: {
    rotateIndicator(indicator, value) {
      const degrees = Math.round(3.6 * value);
      indicator.style.setProperty("--widget-degrees", `${degrees}deg`);
    },
    popperOffset({ placement }) {
      if (placement === "right-end") {
        return [-30, -30];
      }

      return [30, -35];
    },
  },
};
</script>

<style lang="scss" scoped>
.react-score {
  --widget-font-size: 22px;
  --widget-transform: none;
  --widget-degrees: 0deg;
  --widget-hole-size: 48px;

  &__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__high-react-label {
    background-color: $react-score-warning;
    color: white;
    width: 70px;
    height: 20px;
    border-radius: 19px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 8px;
    margin-top: 20px;
  }

  &__tiles {
    background-color: #e6ebef;
    border-radius: 5px;
    width: 93px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__score {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
  }

  &__label-title {
    margin-left: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
  }

  &__value-title {
    margin-top: 4px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__dropdown {
    width: 194px;
    border-width: 0 0 1px 1px;
    background: white;
    color: var(--s-color-font-default);
  }

  &__stat {
    display: flex;
    gap: var(--s-spacing-md);
    align-items: center;
  }

  &__content {
    margin-top: 20px;
    font-size: 14px;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    border-radius: 100%;
    background-color: #3e4860;
    transform: var(--widget-transform);

    &--red {
      background-color: $react-score-warning;
    }

    &-mask {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      width: 64px;
      border-radius: 100%;
      background-image: conic-gradient(
        transparent var(--widget-degrees),
        #bac0ca var(--widget-degrees)
      );
      position: absolute;
    }

    &-end {
      height: 64px;
      width: 64px;
      position: absolute;
      display: flex;
      justify-content: center;
      transform: rotate(var(--widget-degrees));

      div {
        margin-right: 4px;
        height: 8px;
        width: 4px;
        background-image: radial-gradient(
          farthest-side at 0 4px,
          transparent 100%,
          #cccfd2 100%
        );
      }
    }

    &-hole {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--widget-hole-size);
      width: var(--widget-hole-size);
      border-radius: 100%;
      background-color: #fff;
      z-index: 2;
      font-size: var(--widget-font-size);
      font-weight: var((--s-font-weight-bold));
      line-height: 1;
      letter-spacing: -0.04em;
      color: #34404b;
    }
  }

  &__button {
    display: flex;
    gap: var(--s-spacing-sm);
    align-items: center;
  }

  &__button-indicator {
    --widget-transform: scale(0.1875) translate(-136px, -136px);
    --widget-hole-size: 28px;

    width: 13px;
    height: 13px;
  }
}

:deep(.c-base-button-dropdown__body) {
  padding: 20px 16px;
}
</style>
