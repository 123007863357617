import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useMediaRequestsStore } from "shared/stores/mediaRequests";
import { useUserStore } from "shared/stores/user";

async function callback() {
  if (!useUserStore().isLoggedIn || useGlobalStore().technicalServerError) {
    return;
  }

  await useMediaRequestsStore().fetchNewMediaRequestsCount();
}

export default new Poller({ callback });
