<template>
  <div class="tv-card-preview">
    <img
      class="tv-card-preview-image full-width"
      :class="{ 'tv-card-preview-image-border': !hideBorderTop }"
      height="auto"
      :src="previewImageUrl"
      style="height: auto"
      :alt="$t('player_popout_tv_card_control.clip_preview')"
    />
    <div
      class="tv-card-control flex justify-center items-center full-width full-height"
    >
      <UniversalPlayerControlWidget
        :clip="clip"
        class="tv-card-universal-control"
        size="30px"
        @play="$emit('play')"
      />
    </div>
  </div>
</template>

<script>
import UniversalPlayerControlWidget from "shared/components/players/UniversalPlayerControlWidget.vue";

export default {
  name: "PlayerPopoutTVCardControl",
  components: {
    UniversalPlayerControlWidget,
  },
  props: {
    previewImageUrl: {
      type: String,
      default: "none",
    },
    clip: {
      type: Object,
      required: true,
    },
    hideBorderTop: {
      type: Boolean,
    },
  },
  emits: ["play"],
};
</script>

<style lang="scss" scoped>
.tv-card-preview {
  margin-bottom: -3px;
  position: relative;
}

.tv-card-preview-image {
  border-bottom: 1px solid $ds-denim-3;
}

.tv-card-preview-image-border {
  border-radius: $streem-media-top-border-radius;
}

.tv-card-control {
  position: absolute;
  top: 0;
}
</style>
