import { executeTransition, TransitionPresets } from "@vueuse/core";
import type { CSSProperties } from "vue";
import { computed, onBeforeUnmount, onMounted, Ref, ref, watch } from "vue";

import type { Nullable } from "shared/types";

export type Model = boolean;

export interface Props {
  label?: string;
  caption?: string;
  bordered?: boolean;
  dense?: boolean;
  fitContainer?: boolean;
  scrollContainer?: boolean;
  boldLabel?: boolean;
  group?: string;
  menu?: boolean;
  defaultOpened?: boolean;
  hoverToggle?: boolean;
  active?: boolean;
  duration?: number;
  noPadding?: boolean;
  stickyHeader?: boolean;
  headerStyle?: CSSProperties;
  disabled?: boolean;
}

interface PanelGroup {
  content: HTMLDivElement;
  closePanel: () => void;
}

interface PanelGroups {
  [key: string]: PanelGroup[];
}

const panelGroups: PanelGroups = {};

export default function useExpansionPanel(
  modelValue: Ref<Model>,
  props: Props
) {
  const expanded = ref(props.defaultOpened || modelValue.value);
  const container = ref<Nullable<HTMLDivElement>>(null);
  const content = ref<Nullable<HTMLDivElement>>(null);
  const containerHeight = ref(0);
  const inTransition = ref(false);
  const mouseover = ref(false);
  let transitionId = 0;

  const containerStyle = computed<CSSProperties>(() => {
    if (inTransition.value) {
      return {
        height: `${containerHeight.value}px`,
      };
    }

    return {};
  });

  async function updatePanel(): Promise<void> {
    if (!container.value || !content.value || !props.duration) return;

    transitionId += 1;
    const id = transitionId;
    inTransition.value = true;
    const from = container.value.offsetHeight;
    const to = expanded.value ? content.value.offsetHeight : 0;

    await executeTransition(containerHeight, from, to, {
      duration: props.duration,
      transition: TransitionPresets.easeInOutCubic,
      abort() {
        return id !== transitionId;
      },
    });

    if (id === transitionId) inTransition.value = false;
  }

  function closePanelGroup() {
    if (!props.group) {
      return;
    }

    panelGroups[props.group].forEach((panel: PanelGroup) => {
      if (panel.content === content.value) return;
      panel.closePanel();
    });
  }

  function togglePanel() {
    if (props.disabled) return;
    expanded.value = !expanded.value;
    closePanelGroup();
    updatePanel();
  }

  function openPanel() {
    if (expanded.value) return;
    expanded.value = true;
    updatePanel();
  }

  function closePanel() {
    if (!expanded.value) return;
    expanded.value = false;
    updatePanel();
  }

  function addToPanelGroup() {
    if (!props.group) return;

    if (!panelGroups[props.group]) {
      panelGroups[props.group] = [];
    }

    panelGroups[props.group].push({
      content: content.value!,
      closePanel,
    });
  }

  function removeFromPanelGroup() {
    if (!props.group || !panelGroups[props.group].length) return;

    panelGroups[props.group] = panelGroups[props.group].filter(
      (panel) => panel.content !== content.value
    );
  }

  function onMouseOver() {
    mouseover.value = true;
  }

  function onMouseOut() {
    mouseover.value = false;
  }

  watch(modelValue, () => {
    if (modelValue.value === expanded.value) return;
    expanded.value = modelValue.value;
    closePanelGroup();
    updatePanel();
  });

  watch(expanded, () => {
    Object.assign(modelValue, {
      value: expanded.value,
    });
  });

  onMounted(() => {
    addToPanelGroup();
  });

  onBeforeUnmount(() => {
    removeFromPanelGroup();
  });

  return {
    container,
    content,
    containerStyle,
    mouseover,
    expanded,
    inTransition,
    togglePanel,
    openPanel,
    closePanel,
    onMouseOver,
    onMouseOut,
  };
}
