import { defineStore } from "pinia";

type SearchScope = "all" | "journalists" | "streams";

interface RootState {
  searchOpen: boolean;
  searchQuery: string;
  searchScope: SearchScope;
}

export const useUniversalSearchStore = defineStore("universalSearch", {
  state: (): RootState => ({
    searchOpen: false,
    searchQuery: "",
    searchScope: "streams",
  }),
  actions: {
    hideSearch() {
      this.searchOpen = false;
    },
    showSearch() {
      this.searchOpen = true;
    },
    setSearchQuery(query: string) {
      this.searchQuery = query;

      if (this.searchQuery) this.searchOpen = true;
    },
    setSearchScope(scope: SearchScope) {
      this.searchScope = scope;
    },
  },
});

export default useUniversalSearchStore;
