<template>
  <InputSelect
    :options="filteredListOptions"
    :disabled="disabled"
    :loading="loading"
    option-label="name"
    option-prop="id"
    :dropdown-text="$t('outreach_recipient_list_dropdown.add_to_outreach_list')"
    new-design
    @click="populateOptions"
    @update:model-value="addToExistingList"
  >
    <template #beforeOptions>
      <InputText
        v-model="filter"
        :placeholder="$t('outreach_recipient_list_dropdown.create_new')"
        class="input"
        :auto-focus="$isDesktop"
        clearable
        tabindex="-1"
        @submit="addToNewList"
      />
    </template>

    <template #empty>
      <div
        class="list-option__empty"
        @click="addToNewList"
      >
        <span v-if="filter">
          {{
            $t("outreach_recipient_list_dropdown.create_list", { filter })
          }}</span
        >

        <span v-else>{{
          $t("outreach_recipient_list_dropdown.type_to_find")
        }}</span>
      </div>
    </template>
  </InputSelect>
</template>

<script>
import { storeToRefs } from "pinia";

import { InputSelect, InputText } from "shared/components/base";
import { OutreachRecipientList } from "shared/resources";
import { useUserStore } from "shared/stores/user";

export default {
  name: "OutreachRecipientListDropdown",
  components: {
    InputSelect,
    InputText,
  },
  props: {
    selectedLists: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["add"],
  setup() {
    const userStore = useUserStore();
    const { currentUser, organisation } = storeToRefs(userStore);

    return { currentUser, organisation };
  },
  data() {
    return {
      filter: "",
      options: [],
      loading: false,
    };
  },
  computed: {
    listsNotAlreadySelected() {
      const selectedListIds = this.selectedLists.map(({ id }) => Number(id));

      return this.options.filter(
        ({ id }) => !selectedListIds.some((listId) => listId === Number(id))
      );
    },
    filteredListOptions() {
      const filter = this.filter.toLowerCase();

      return this.listsNotAlreadySelected.filter(({ name }) =>
        name.toLowerCase().includes(filter)
      );
    },
  },
  methods: {
    async populateOptions() {
      if (this.options.length) return;

      this.loading = true;

      try {
        const { data } = await OutreachRecipientList.where({
          ownAndPublicLists: true,
        })
          .order({ id: "desc" })
          .per(999)
          .all();

        this.options = data;
      } catch {
        this.$q.notify(
          this.$t("outreach_recipient_list_dropdown.load_outreach_lists_error")
        );
      } finally {
        this.loading = false;
      }
    },
    addToNewList() {
      if (this.filter.length) {
        const list = new OutreachRecipientList({
          name: this.filter,
          organisationId: this.organisation.id,
          userId: this.currentUser.id,
        });

        this.$emit("add", list);
        this.filter = "";
      }
    },
    addToExistingList(list) {
      this.$emit("add", list);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-option {
  &__empty {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
  }
}
</style>
