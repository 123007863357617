<template>
  <hr />
</template>

<style lang="scss" scoped>
hr {
  border: 0;
  background: var(--s-color-denim-2);
  margin: 0;
  flex-shrink: 0;
  height: 1px;
}
</style>
